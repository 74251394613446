import React, { useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import PropTypes from "prop-types";
import { Modal } from 'react-responsive-modal';
import { ClientModel, EmployeeModel, UserModel } from '../Models/Models';
import { fetchViewList8, fetchViewListEmployeeChilds } from '../Services/Services';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import { Container, Grid, SvgIconProps } from '@material-ui/core';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import Typography from '@material-ui/core/Typography/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
interface MenuProps {
  children: EmployeeModel[];
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  setTreeUser: React.Dispatch<React.SetStateAction<number>>;
  currentNumeration: number;
}
interface OpenStateProps {
  Id: Number;
  State: boolean;

}
interface levelProps {
  Id: Number;


}


interface NumberIconProps {
  Id: any;
}
const NumberIcon: React.FC<NumberIconProps> = ({ Id }) => {
  const [numeration, setNumeration] = React.useState<number>(0);
  return (
    <div style={{ minWidth: "20px" }}>
      <svg height="15" width="15" >
        <circle cx="7.5" cy="7.5" r="7.5" stroke="black" strokeWidth="0" fill="#3C95DF" />
        <text
          fill="white"
          fontFamily="Verdana"
          textAnchor="middle"
           alignmentBaseline="middle"
          x="7.5"
          y="8">{Id}</text>

      </svg></div>
  )


}


const useTreeItemStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    "&:focus > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: "var(--tree-view-color)"
    }
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightBold
    }
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2)
    }
  },
  expanded: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",

  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0)
  },
  labelIcon: {
    marginRight: theme.spacing(1)
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
    fontSize: "1.32rem",
    minHeight:"40px"
  }
}));
type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
 
  labelInfo?: string;
  labelText: string;
  labelNumber?: string;
};
function StyledTreeItem(props: StyledTreeItemProps) {
  const classes = useTreeItemStyles();
  const { labelText, labelNumber,  labelInfo, color, bgColor, ...other } = props;



  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <NumberIcon Id={labelNumber} />

          <Typography variant="body2" className={classes.labelText} >
            {labelText}
          </Typography>

        </div>
      }
      style={{
        // '--tree-view-color': color?color:"",
        // '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        // selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}
StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired
};


const useStyles = makeStyles((theme) => ({
  root: {

    height: 300,
    flexGrow: 1,
    maxWidth: 400,
    fontSize: "20px",
    // display:"inline-block"

  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const DropDownOption: React.FC<MenuProps> = ({ children, setStatus, setTreeUser, currentNumeration }) => {

  const [numeration, setNumeration] = React.useState<number>(currentNumeration);
  useEffect(() => {

    setNumeration(currentNumeration + 1);
    //  let myIcon2 = Welcome;
    //  myIcon2.increment(currentNumeration.toString())
  }, []);



  function changeUser(m: any) {

    setStatus(m.CompleteName.toString())
    setTreeUser(+m.Id)

  }


  return (
    <div>

      {children && children.map(m => {
        return <div style={{ fontSize: "1.2rem", textAlign: "left", display: "inline-block" }} >


          <div style={{ display: "inline-block", marginLeft: currentNumeration * 10 + "px" }}>

            <StyledTreeItem nodeId={m.Id.toString()} labelText={m.CompleteName ? m.CompleteName.toString() : ""} onClick={() => { changeUser(m) }}  labelNumber={currentNumeration.toString()}  >
              {
                m.Children.length > 0 ? <DropDownOption children={m.Children} setStatus={setStatus} setTreeUser={setTreeUser} currentNumeration={numeration}  ></DropDownOption> : null
              }

            </StyledTreeItem>


          </div>



        </div>
      })}
    </div>
  );
}
interface TreeViewRedProps {

  setStatus: React.Dispatch<React.SetStateAction<string>>;
  setTreeUser: React.Dispatch<React.SetStateAction<number>>;

}


const TreeViewRed: React.FC<TreeViewRedProps> = ({ setStatus, setTreeUser }) => {




  const classes = useStyles();
  const [EmployeeList, setEmployeeList] = React.useState<EmployeeModel>();

  useEffect(() => {

    getDataFromUser()
  }, []);

  async function getDataFromUser() {


    var guardado = sessionStorage.getItem('user');
    if (guardado == null || guardado == undefined) {

    } else {
      let currentUser: UserModel = JSON.parse(guardado ? guardado : "");

      let currentEmployeee: EmployeeModel = await fetchViewListEmployeeChilds(currentUser.EmployeeId.toString());
      setEmployeeList(currentEmployeee);

    }

  }

  function changeUser(m: any) {

    setStatus(m.CompleteName.toString())
    setTreeUser(+m.Id)

  }


  return (
    <Container style={{ overflow: "auto" }}>
      <Grid container >
        <Grid item xs={12} xl={12} >
          <div>
        {
          EmployeeList ?<TreeView

          className={classes.root}

          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >

          <div style={{ display: "inline-block" }}>

            <StyledTreeItem style={{ textAlign: "left", fontSize: "1.2rem" }} nodeId={"1"} labelNumber={"1"} onClick={() => { changeUser(EmployeeList) }}


              labelText={EmployeeList?.CompleteName ? EmployeeList?.CompleteName.toString() : ""}
            
            >
              <DropDownOption children={EmployeeList ? EmployeeList.Children : []} setStatus={setStatus} setTreeUser={setTreeUser} currentNumeration={2} ></DropDownOption>

            </StyledTreeItem>

          </div>
        </TreeView> :  <CircularProgress />
        }
            
          </div>

        </Grid>

      </Grid>

    </Container>


  );

}
export default TreeViewRed;







