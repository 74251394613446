import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Row from '../Components/Row';
//import {ProductType} from '../Models/Models';
import {fetchViewEmployeeDetail, fetchViewList, fetchViewListCatProducts, fetchViewListEmployeeChilds} from '../Services/Services';
import { Cart, ClientModel, EmployeeModel, UserModel } from '../Models/Models';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
// import FooterPage from '../Components/Footer';
import Divider from '@material-ui/core/Divider/Divider';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Container from '@material-ui/core/Container';
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    dividerColor:{
      paddingTop: 2,
      paddingBottom: 2,
      marginTop:"20px",
      marginBottom:"20px",
      
      color:'black',

    }, formControl: {
      margin: theme.spacing(1),
      minWidth: 320,
    },labelRoot: {
      fontSize: 15,
      color: "black",
      "&$labelFocused": {
          color: "purple"
      }, marginLeft: "15px"
  },
  }));

  function Alert(props:any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function dropCart() {
    let cart: Cart = { UserName: "Nick", UserNit: "11010110", ProductCartDetail: [] }
    sessionStorage.setItem("keyName", JSON.stringify(cart));    
}

  const useStyles2 = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));
  
  

  interface OrderPageProps{

    SetCurrentCart: Function;
    CurrentCart:Cart;
  } 
  
  
  // const areEqual = (prevProps, nextProps) => true;

  // const MyComponent = React.memo(props => {
  //   return /*whatever jsx you like */
  // }, areEqual);
  
const ProductsPage :React.FC<OrderPageProps> = ({CurrentCart,SetCurrentCart}) => {

  // let cart : Cart ={UserName:""  
  let [currentProductTypes,setCurrentProductTypes] = useState([]);
  let [currentProductTypes2,setCurrentProductTypes2] = useState<any[]>([]);
  const [parentName, setParentName] = useState<string>("");
  const [userSelected, setUserSelected] = useState<UserModel>();
  let [currentUserLogged, setCurrentUserLogged] = useState<any>();
  const [EmployeeList, setEmployeeList] = React.useState<EmployeeModel>();
  const [LocalizationId, setLocalizationId] = React.useState<String>("");
  const [EmployeeType, setEmployeeType] = React.useState<String>("");
  useEffect(() => {

    let cookieUser: any = sessionStorage.getItem("user");
    let currentUser: any = JSON.parse(cookieUser);

    if (currentUser !== undefined && currentUser !== null) {
        setCurrentUserLogged(currentUser);               
        
    }

}, []);
useEffect(() => {

  
}, []);
useEffect(() => {

  getDataFromUser()
}, []);

async function getDataFromUser() {
  var guardado = sessionStorage.getItem('user');
  if (guardado == null || guardado == undefined) {

  } else {
      let currentUser: UserModel = JSON.parse(guardado ? guardado : "");
      setCurrentUserLogged(currentUser);
      //setParentName(currentUser.UserName.toString());
      let currentEmployeee: EmployeeModel = await fetchViewListEmployeeChilds(currentUser.EmployeeId.toString());
      setEmployeeList(currentEmployeee);

  }

}

  // let [currentCart,setCurrentCart] = useState(cart);

  // useEffect(() => {

  //   console.log("Current Cart-->",currentCart);
  // }, [currentCart]);

  const classes = useStyles();
  const classes2 = useStyles2();
  const [respon, setRespon] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [cat, setCat] = React.useState(3);


  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event:any, reason:any) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const { height, width } = useWindowDimensions();

  function getWindowDimensions() {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        width,
        height
      };
    }
  useEffect(() => {
     
    if(width<500){
      setRespon(true);
    }else{setRespon(false);}
  },[width]);


  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
  }


    useEffect(() => {

        const getProductTypes = async () => {
          console.log()
          let List : any =   await fetchViewListCatProducts()
          setCurrentProductTypes(List);
          // console.log("-->",List);
        };
        dropCart();
        getProductTypes();
       sessionStorage.setItem('parentName', parentName)
      }, [parentName]);

      useEffect(() => {

        const getProductTypes = async () => {
          console.log()
          let List : any =   await fetchViewListCatProducts()
          setCurrentProductTypes(List);
          // console.log("-->",List);
        };
      
        getProductTypes(); 
        
      }, [CurrentCart.ProductCartDetail]);

      const handleChange = (event:any) => {
        console.log("-->",event)
        setCat(event.target.value);
      };
      useEffect(() => {

        const getProductTypes = async () => {
          console.log()
          let List : any =   await fetchViewList()
          console.log("list",List)
          setCurrentProductTypes2(List);
          // console.log("-->",List);
        };
      
        getProductTypes();
       
      }, []);

      useEffect(() => {

        const getProductTypes = async () => {
          console.log()
          let List : any =   await fetchViewList()
          setCurrentProductTypes2(List);
          // console.log("-->",List);
        };
      
        getProductTypes();
      
        
      }, [CurrentCart.ProductCartDetail]);
  async function handleChangeUser(e:any)
{
  setParentName((e.target as HTMLInputElement).value)
  console.log("productttt",((e.target as HTMLInputElement).value));
  let List : any =   await fetchViewEmployeeDetail((e.target as HTMLInputElement).value)
  console.log("productttt",List);  
  setLocalizationId(List.LocalizationId.toString());
  setEmployeeType(List.EmployeeType.toString());
 
};
  return (
    <Container fixed style={{backgroundColor:"#DCDCDC" ,marginTop:"-20px"}} >
    <div className={classes.root}  style={{backgroundColor:"#DCDCDC" ,marginTop:"-40px"}}>


      <h1  style={{ fontSize:"4.5rem", fontWeight:"bold"}} >Realiza Tu Pedido</h1>      
      <br></br>
      <FormControl className="form-control">
                                                        <InputLabel className={classes.labelRoot} id="demo-simple-select-label">Referido a:</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={parentName}
                                                            onChange={e => handleChangeUser(e)}
                                                            disableUnderline
                                                            style={{ marginLeft: "20px", fontSize: "15px" }}                                                        >
                                                            <MenuItem value={currentUserLogged?.EmployeeId.toString()}>{currentUserLogged?.UserName}</MenuItem>
                                                            {EmployeeList && EmployeeList.Children.map(m => {
                                                                return <MenuItem value={m.Id.toString()}>{m.CompleteName}</MenuItem>
                                                            })}
                                                        </Select>
                                                    </FormControl>
      {/* <Divider></Divider > */}
      {!respon&&LocalizationId&&EmployeeType&&parentName!=""&&
        currentProductTypes.length > 0 ?
          currentProductTypes.map((item, index) => {
            return <div><Divider classes={{root: classes.dividerColor}} ></Divider ><Row 
              key={index}
              LocalizationId={LocalizationId}
              EmployeeType={EmployeeType}
              ProductTypeDetail={item}
              CurrentCart={CurrentCart}
              SetCurrentCart={SetCurrentCart}
              HandleClick = {handleClick}
              onlySection={false}
            ></Row></div>
          }) : <></>
      }
{respon&&parentName!=""? 
  <FormControl variant="filled" className={classes.formControl}  >
  <InputLabel id="demo-simple-select-filled-label"  style={{fontSize:"25px",textAlign:"center"}}>Categorias</InputLabel>
  <Select
    labelId="demo-simple-select-filled-label"
    id="demo-simple-select-filled"
    value={cat}
    onChange={handleChange}
    style={{fontSize:"30px"}}
  >
    {
  currentProductTypes2.length > 0 ?
  currentProductTypes2.map((item, index) => {
    return  <MenuItem value={item.ProductTypeId}>{item.ProductTypeName}</MenuItem>

    }) : <></>
}
  </Select>
</FormControl>
:<></>
}
      {respon&&LocalizationId&&EmployeeType&& parentName!=""&&
        currentProductTypes2.length > 0 ?
        currentProductTypes2.map((item2, index) => {if(item2.ProductTypeId==cat){
            return <div><Divider classes={{root: classes.dividerColor}} ></Divider ><Row 
              key={index}
              LocalizationId={LocalizationId}
              EmployeeType={EmployeeType}
              ProductTypeDetail={item2}
              CurrentCart={CurrentCart}
              SetCurrentCart={SetCurrentCart}
              HandleClick = {handleClick}
              onlySection={true}
            ></Row></div>   }
          }) : <></>
      }

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Objeto agregado al carrito
        </Alert>
      </Snackbar>
      </div>

      </Container>


  );
}



export default ProductsPage;
