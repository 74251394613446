import { ClientModel, ClientPromotorModel, EmployeeModel, Order, OrderDetailItem, OrderReceive, PeriodModel, ProductDetail, ProductType, ReportModel } from "../Models/Models";

export const fetchViewList8 = async (Username: String, Password: String): Promise<ClientModel> => {

  const data = await fetch(`https://montecristows.ttsoluciones.com/api/user?username=${Username}&password=${Password}`);
  const result = await data.json();

  return result;
};



export const fetchViewPersonalPurchases = async (EmployeeId: String, Period: String): Promise<Array<ReportModel>> => {

  const data = await fetch(`https://montecristows.ttsoluciones.com/Sales/EmployeePeriodSales?employeeId=${EmployeeId}&periodId=${Period}`);
  const result = await data.json();

  return result;
};
export const fetchViewByPeriodWallet = async (EmployeeId: String, Period: String): Promise<Array<ReportModel>> => {

  const data = await fetch(`   https://montecristows.ttsoluciones.com/Sales/EmployeePeriodWalletByLevel?employeeId=${EmployeeId}&periodId=${Period}`);
  const result = await data.json();

  return result;
};
export const fetchViewByWallet = async (EmployeeId: String): Promise<Array<ReportModel>> => {

  const data = await fetch(`https://montecristows.ttsoluciones.com/Sales/EmployeeWallet?employeeId=${EmployeeId}`);
  const result = await data.json();

  return result;
};


export const fetchViewListPeriods = async (): Promise<Array<PeriodModel>> => {

  const data = await fetch(`https://montecristows.ttsoluciones.com/Base/Periods?type=PROMOTORES`);
  const result = await data.json();

  return result;
};


export const fetchViewListEmployeeChilds = async (EmployeeId: String): Promise<EmployeeModel> => {

  const data = await fetch(` https://montecristows.ttsoluciones.com/employee/GetChildreenTree?employeeId=${EmployeeId}`);
  const result = await data.json();

  return result;
};


export const fetchViewClient = async (EmployeeId: String, Period: String): Promise<Array<ReportModel>> => {

  const data = await fetch(`https://montecristows.ttsoluciones.com/Sales/EmployeePeriodChildrenSalesByType?employeeId=${EmployeeId}&periodId=${Period}&employeeType=CLIENTE PROMOTOR`);
  const result = await data.json();

  return result;
};
export const fetchViewAgent = async (EmployeeId: String, Period: String): Promise<Array<ReportModel>> => {

  const data = await fetch(`https://montecristows.ttsoluciones.com/Employee/GetPeriodNetwork?employeeId=${EmployeeId}&periodId=${Period}&employeeType=AGENTE PROMOTOR`);
  const result = await data.json();

  return result;
};



//RED
export const fetchViewRed = async (EmployeeId: String, Period: String): Promise<Array<ReportModel>> => {

  const data = await fetch(`https://montecristows.ttsoluciones.com/Employee/GetPeriodNetwork?employeeId=${EmployeeId}&periodId=${Period}&employeeType=AGENTE PROMOTOR`);
  const result = await data.json();

  return result;
};


export const fetchViewSelectedPromotor = async (EmployeeId: String, Period: String): Promise<Array<ClientPromotorModel>> => {

  const data = await fetch(`https://montecristows.ttsoluciones.com/Sales/EmployeePeriodChildrenSalesByType?employeeId=${EmployeeId}&periodId=${Period}&employeeType=AGENTE PROMOTOR`);
  const result = await data.json();

  return result;
};


export const fetchViewListCatProducts = async (): Promise<Array<ProductType>> => {
  const data = await fetch(`https://montecristows.ttsoluciones.com/product/deliveryproductypes`);
  const viewList = await data.json();
  return viewList;
};

export const fetchViewEmployeeDetail = async (Key: String): Promise<ClientModel> => {
    
  const data = await fetch(`https://montecristows.ttsoluciones.com/api/employee/${Key}`);
  const viewList = await data.json();
  return viewList;
};

export const fetchViewListProducts = async (Key: String, LocalizationId:String, EmployeeType:String): Promise<Array<ProductDetail>> => {
  //const data = await fetch(`https://montecristows.ttsoluciones.com/Product/DeliveryProductsByTypeId?productTypeId=${Key}`);
  
  const data = await fetch(`https://montecristows.ttsoluciones.com/Product/DeliveryProductsByLocalizationTypeId?productTypeId=${Key}&localizationId=${LocalizationId}&employeeType=${EmployeeType}`);
  

  const viewList = await data.json();
  return viewList;
};
// /Sales/EmployeePeriodSales?employeeId=$employeeId&periodId=$periodId

//https://montecristows.ttsoluciones.com/Sales/EmployeePeriodChildrenSalesByType?employeeId=2767&periodId=123&employeeType=AGENTE PROMOTOR

export const fetchViewListOrdersDetail = async (clienId: string): Promise<Array<OrderReceive>> => {
  // https://cottonviuws.ttsoluciones.com/api/client?username=test-email@gmail.com&password=saas
  //http://montecristows.ttsoluciones.com/Order/GetByEmployee?EmployeeId=6854
  const data = await fetch(`https://montecristows.ttsoluciones.com/Order/GetByEmployee?EmployeeId=${clienId}`);
  //  const data = await fetch(`https://cottonviuws.ttsoluciones.com/Order/getClientOrders?clientId=35200`);
  const result = await data.json();
  return result;
};

export const fetchViewList = async (): Promise<Array<ProductType>> => {
  const data = await fetch(`https://montecristows.ttsoluciones.com/product/deliveryproductypes`);
  const viewList = await data.json();
  return viewList;
};



export const fetchViewRegisterClient = async (Client: ClientModel) => {
  console.log("ClientClient", Client);
  try {
    const data = await fetch('https://montecristows.ttsoluciones.com/Client/Register', {
      method: 'POST',
      headers: {        
        'Content-Type': 'application/json',        
      },
      body: JSON.stringify(
        {
        "address": Client.address,
        "billName": Client.billName,
        "billNit": Client.billNit,
        "ciudad": Client.ciudad,
        "documentNumber": Client.documentNumber,
        "email": Client.email,
        "firstName": Client.firstName,
        "lastName1": Client.lastName1,
        "lastName2": Client.lastName2,
        "latitude": 0,
        "localizationId": 1,
        "longitude": 0,
        "mobile": Client.mobile,
        "name": Client.name,
        "observaciones": Client.observaciones,
        "parentId": Client.parentId,
        "password": Client.password,
        "phone": Client.phone,
        "tipo": Client.tipo
      }
      
      
      )
    })
    const result = await data.json();
    return result;
  }
  catch (error) {
    console.log('*** hubo error');
  }

};
export const SendOrder2 = async (order: Order): Promise<any> => {

  console.log("order", order)
  let arrayOfItems: OrderDetailItem[] = [];
  for (let i = 0; i < order.items.length; i++) {
    let item: OrderDetailItem = { ProductId: order.items[i].productId, ProductName: order.items[i].name, ProductQuantity: order.items[i].quantity, ProductSalePrice: order.items[i].price, description: order.items[i].observation, ProductImage: "" }
    arrayOfItems.push(item);
  }
  try {

    console.log("order--->>to send", order);
    const data = await fetch('https://montecristows.ttsoluciones.com/Employee/EmployeeOrder', {
      method: 'POST',  
      headers: {
        'Content-Type': 'application/json',        
      },
      body: JSON.stringify({

        "branchId": order.branchId,
        "clientId": order.clientId,
        "employeeId": order.clientId,
        "code": "PEDIDO",
        "items": arrayOfItems,
        "latitude": order.latitude,
        "longitude": order.longitude,
        "observation": order.observation,
        "scheduledDate": order.scheduledDate,
        "tableId": order.tableId,
        "type": "WEB",
        "userId": order.userId,
        "state": order.state,
        "billName": order.billName,
        "billNit": order.billNit,
        "address": order.address


      })


    })
    console.log("data", data)
    const viewList = await data.json();
    return viewList;

  } catch (error) {
    console.log('*** hubo error');
  }

}
