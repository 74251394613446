import React, { useState } from 'react';

import './App.css';
import LoginForm from './Components/LoginForm';
import ReportsPage from './Pages/ReportsPage';
import RegisterPage from './Pages/RegisterPage';
import ProductsPage from './Pages/ProductsPage';
import { Cart } from './Models/Models';
import HeaderComponent from './Components/HeaderComponent';


const AppHeader :React.FC= () => {
  let cart : Cart ={UserName:"Nick",UserNit:"11010110",ProductCartDetail:[]}
  let [currentCart,setCurrentCart] = useState(cart);
  return (
    <div className="AppHeader">
      <HeaderComponent CurrentCart={currentCart} SetCurrentCart = {setCurrentCart}></HeaderComponent>

    </div>
  );
}

export default AppHeader;
