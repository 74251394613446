import React, { useEffect } from 'react';
// import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
// import Drawer from '@material-ui/core/Drawer';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import List from '@material-ui/core/List';
// import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { ClientModel, PeriodModel, AgentPromotorModel, ClientPromotorModel, ReportModel, UserModel, WalletModel, ReportWalletLevelModel, ReportModelToShow, WalletModelToShow } from '../Models/Models';
import { fetchViewListPeriods, fetchViewPersonalPurchases, fetchViewByWallet, fetchViewClient, fetchViewAgent, fetchViewByPeriodWallet, fetchViewSelectedPromotor } from '../Services/Services';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TreeViewRed from '../Components/TreeView';
import FormComponent from '../Components/FormComponent';
import { mapperToClientPromotor, mapperToClientPromotor2, mapperToReportModel, mapperToWalletLevel, mapperToWalletModel } from '../utils';
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles(theme => ({
    root: {
      
        width: "100%",
        maxWidth: 400,
        backgroundColor: theme.palette.background.paper,
        fontSize: "20px"
    },
    paper: {
        
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    table: {
        minWidth: 650,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


const ReportsPage: React.FC = () => {


    const classes = useStyles();
    const [dataRow, setDataRow] = React.useState<Array<ReportModelToShow>>([]);
    const [dataRow2, setDataRow2] = React.useState<Array<ReportWalletLevelModel>>([]);
    const [dataRow3, setDataRow3] = React.useState<Array<ClientPromotorModel>>([]);
    const [dataRow4, setDataRow4] = React.useState<Array<AgentPromotorModel>>([]);
    const [currentReport, setCurrentViewStatus] = React.useState("");
    const [dataPeriods, setDataPeriods] = React.useState<Array<PeriodModel>>([]);
    const [period, setPeriod] = React.useState('');
    const [currentViewStatus, setCurrentViewTableStatus] = React.useState<number>(0);
    const [dataWallet, setDataWallet] = React.useState<Array<WalletModel>>([]);
    const [dataWallettoShow, setDataWallettoShow] = React.useState<Array<WalletModelToShow>>([]);
    const [dataRowMiRed1, setDataMiRed1] = React.useState<Array<ReportModel>>();
    const [dataRowMiRed2, setDataMiRed2] = React.useState<Array<AgentPromotorModel>>();

    const [report1, setReport1] = React.useState<boolean>(true);
    const [report2, setReport2] = React.useState<boolean>(true);
    const [report3, setReport3] = React.useState<boolean>(true);
    const [report4, setReport4] = React.useState<boolean>(true);
    const [report5, setReport5] = React.useState<boolean>(true);
    const [report6, setReport6] = React.useState<boolean>(true);
    const [report7, setReport7] = React.useState<boolean>(true);
    






    const [treeUser, setTreeUser] = React.useState<number>(0);
    const [treeUserName, setTreeUserName] = React.useState<string>("");


    const handleChange = (event: any) => {
        setPeriod(event.target.value);
    };

    useEffect(() => {
        var guardado = sessionStorage.getItem('user');
        if (guardado == null || guardado == undefined) {
            console.log("setting")
            sessionStorage.setItem("currentView", "inicio")
            //window.location.reload();
        }

    });

    useEffect(() => {

        getDataPeriods();
    }, []);


    useEffect(() => {
        console.log("treeUser",treeUser)  
        console.log("treeUserName",treeUserName)  
    }, [treeUser,treeUserName]);


    function treeUserSet(){
        
        let cookieUser: any = sessionStorage.getItem("user");
    let currentUserlogged: UserModel = JSON.parse(cookieUser);

    if (currentUserlogged) {
     
        setTreeUser(+currentUserlogged.EmployeeId)
        setTreeUserName(currentUserlogged.UserName.toString())
    }else{
      

    }
    setCurrentViewTable(3, "Reporte 3")

    }

    function setCurrentViewTable(view: number, title: string) {

        setCurrentViewTableStatus(view);
        setCurrentViewStatus(title);
        if (view == 2) {
            getDataWallet();
        }
    }
    useEffect(() => {

        console.log("period",period);
        
    }, [period]);
    useEffect(() => {

        getData();

    }, [period]);

    useEffect(() => {
        //setPeriod('');
        //setDataRow([])
    }, [currentReport]);

    useEffect(() => {
        getDataRed();
    }, [treeUserName, period]);


    useEffect(() => {
        //console.log("setting new report")
        //setReportSelected(true);
    }, [currentReport]);

    //fetchViewListReport
    async function getData() {

        var guardado = sessionStorage.getItem('user');
        if (guardado == null || guardado == undefined) {

        } else {
            let currentUser: UserModel = JSON.parse(guardado ? guardado : "");

            if (period !== "" && currentUser !== null && currentUser !== undefined) {


                let currentList1: Array<any> = await fetchViewPersonalPurchases(currentUser.EmployeeId.toString(), period);


                //if(currentList.length==0){currentList.push({BranchName:"",BillAmount:0,BillCode:"",BillDate:"",BillNumber:0,Source:""})}
                let currentList11: Array<any> = mapperToReportModel(currentList1)

                


                if(currentList11.length==0){
                    setReport1(false)
                    currentList11.push({ BillDate:"-", BillAmount: 0, Source: "-" })
                }
                setDataRow(currentList11);
                let currentList2: Array<any> = await fetchViewByPeriodWallet(currentUser.EmployeeId.toString(), period);

                console.log("dataform2",currentList2)

                let currentList22: Array<any> = mapperToWalletLevel(currentList2);

                
                if(currentList22.length==0){
                    setReport2(false)
                    currentList22.push({ Level: 0, CP: 0, Wallet:  0})
                }
                setDataRow2(currentList22);

                let currentList3: Array<any> = await fetchViewClient(currentUser.EmployeeId.toString(), period);
                let currentList33: Array<any> = mapperToClientPromotor(currentList3);

             
                if(currentList33.length==0){
                    setReport3(false)
                    currentList33.push({ Amount:0 ,Employee:"-",Subtotal:0})
                }
                setDataRow3(currentList33);
                let currentList4: Array<any> = await fetchViewAgent(currentUser.EmployeeId.toString(), period);
                
                

                if(currentList4.length==0){
                    setReport4(false)
                }
                setDataRow4(currentList4);



                let currentList5: Array<any> = await fetchViewByWallet(currentUser.EmployeeId.toString());

                setDataWallet(currentList5);

                // console.log("setting new report1",currentList)
                // console.log("setting new report2",currentList2)
                // console.log("setting new report3",currentList3)
                // console.log("setting new report4",currentList4)

            }
        }
    }

    async function getDataWallet() {

        var guardado = sessionStorage.getItem('user');
        if (guardado == null || guardado == undefined) {

        } else {
            let currentUser: UserModel = JSON.parse(guardado ? guardado : "");

            if (currentUser !== null && currentUser !== undefined) {

                let currentList5: Array<any> = await fetchViewByWallet(currentUser.EmployeeId.toString());
                let currentList55: Array<any> = mapperToWalletModel(currentList5);
                let currentList555: Array<WalletModelToShow> = mapperToWalletModel(currentList5);
                console.log("Wallet",currentList555)
                

                if(currentList555.length==0){
                    setReport5(false)
                }

                setDataWallet(currentList55);
                setDataWallettoShow(currentList555);

            }
        }
    }


    async function getDataRed() {


        if (period !== "" && treeUser !== 0) {



            let currentList6: Array<any> = await fetchViewPersonalPurchases(treeUser.toString(),period);

            let currentList66: Array<any> = mapperToReportModel(currentList6);

           

            if(currentList66.length==0){
                console.log("red1 sin data")
                setReport6(false)

               
                currentList66.push({  BillAmount: 0,
                    BillCode: "-",
                    BillDate: "",
                    BillNumber: 0,
                    BranchName: "",
                    Source: "" })
            }
            setDataMiRed1(currentList66);


            let currentList7: Array<any> = await fetchViewSelectedPromotor(treeUser.toString(),period);
            let currentList77: Array<any> = mapperToClientPromotor2(currentList7);
           

            if(currentList77.length==0){
                setReport7(false)
                currentList77.push({ Amount:0 ,Employee:"-",SumChild2:0})
            }
            setDataMiRed2(currentList77);



        }
    
    }

        async function getDataPeriods() {
            let currentList: Array<PeriodModel> = await fetchViewListPeriods();
            setDataPeriods(currentList);

             setPeriod(currentList[currentList.length-1].PeriodId.toString());
        }


        const [selectedIndex, setSelectedIndex] = React.useState(4);

        function handleListItemClick(event: any, index: any) {
            setSelectedIndex(index);
        }



        return (
            <Container fixed style={{backgroundColor:"#DCDCDC" ,marginTop:"-20px"}} >
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={3} md={3}  >
                        <Slide direction="right" in={true} mountOnEnter unmountOnExit>
                            <div className={classes.root}>
                                <List component="nav" aria-label="main mailbox folders">
                                    <ListItem
                                        button
                                        selected={selectedIndex === 0}
                                        onClick={event => handleListItemClick(event, 0)}
                                    >
                                        <ListItemIcon>
                                            <InboxIcon />
                                        </ListItemIcon>
                                        <ListItemText onClick={() => { setCurrentViewTable(1, "Reporte 1") }} ><span style={{ fontSize: "15px" }}   >Resumen Periodo</span></ListItemText>
                                    </ListItem>
                                    <ListItem
                                        button
                                        selected={selectedIndex === 1}
                                        onClick={event => handleListItemClick(event, 1)}
                                    >
                                        <ListItemIcon>
                                            <DraftsIcon />
                                        </ListItemIcon>
                                        <ListItemText onClick={() => { setCurrentViewTable(2, "Reporte 2") }}   ><span style={{ fontSize: "15px" }}   >Billetera</span></ListItemText>
                                    </ListItem>

                                    <ListItem
                                        button
                                        selected={selectedIndex === 2}
                                        onClick={event => handleListItemClick(event, 2)}
                                    >
                                        <ListItemIcon>
                                            <DraftsIcon />
                                        </ListItemIcon>
                                        <ListItemText onClick={() => { treeUserSet() }}   ><span style={{ fontSize: "15px" }}   >Mi Red</span></ListItemText>
                                    </ListItem>
                                </List>
                            </div>
                        </Slide>
                        {currentViewStatus == 3 ? <TreeViewRed setStatus={setTreeUserName} setTreeUser={setTreeUser}    ></TreeViewRed> : <></>}
                    </Grid>

                    {currentViewStatus == 1 ?
                        <Grid item xs={12} lg={9} md={9}>
                            {currentReport ? <div>

                                <Grid container spacing={1}>
                                    <Grid item xs={2} ><FormControl className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label" style={{ fontSize: "15px" }}>PERIODO</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={period}
                                            onChange={handleChange}
                                        >
                                            {dataPeriods.map((row, index) => (
                                                <MenuItem key={index} value={row.PeriodId.toString()} >{row.PeriodDescription}</MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                    </Grid>
                                    {/* <Grid item xs={10}> <h2>{currentReport}</h2> </Grid> */}
                                </Grid>
                               
                                <FormComponent dataRow={dataRow} hasData={report1} currentReport={"RESUMEN PERIODO"} hasTotal={true} hasTwo={false} ></FormComponent>
                                <br />
                                <FormComponent dataRow={dataRow2}  hasData={report2}  currentReport={"BILLETERA POR NIVEL"} hasTotal={true} hasTwo={true}  ></FormComponent>
                                <br />
                                <FormComponent dataRow={dataRow3}  hasData={report3}  currentReport={"RESUMEN CLIENTE REFERIDOS"} hasTotal={true} hasTwo={true}  ></FormComponent>
                                <br />

                                <FormComponent dataRow={dataRow4}  hasData={report4} currentReport={"RESUMEN PROMOTORES REFERIDOS"} hasTotal={false} hasTwo={false}  ></FormComponent>
                            </div> : <></>}
                        </Grid> : <></>

                    }

                    {
                        currentViewStatus == 2 ?
                            <Grid item xs={12} lg={9} md={9}>
                                {currentReport ? <div>

                                    <FormComponent dataRow={dataWallettoShow} hasData={report5}  currentReport={"BILLETERA MONTECRISTO"} hasTotal={false} hasTwo={false} ></FormComponent>

                                </div> : <></>}
                            </Grid>
                            : <></>
                    }


                    {currentViewStatus == 3 ?
                        <Grid item xs={12} lg={9} md={9}>
                            {currentReport ? <div>

                                <Grid container spacing={1}>
                                    <Grid item xs={12}   lg={6} md ={6}>
                                        
                                        <div style={{display: "inline-block"}}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-simple-select-label" style={{ fontSize: "15px" }}>PERIODO</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={period}
                                                onChange={handleChange}
                                            >
                                                {dataPeriods.map((row, index) => (
                                                    <MenuItem key={index} value={row.PeriodId.toString()} >{row.PeriodDescription}</MenuItem>
                                                ))}

                                            </Select>
                                         
                                        </FormControl>
                                        
                                        </div>
                                        
                                    </Grid>
                                    <Grid item xs={12}  lg={6} md ={6}>
                                        
                                      
                                         <div style={{display: "inline-block",marginTop:"25px",fontWeight:"bold" }}>
                                             Usuario Seleccionado:{treeUserName}
                                             </div>
                                    </Grid>
                                    {/* <Grid item xs={10}> <h2>{currentReport}</h2> </Grid> */}
                                </Grid>
                                {
                                dataRowMiRed1 ? <FormComponent dataRow={dataRowMiRed1} hasData={true}  currentReport={"COMPRAS PERSONALES"} hasTotal={true} hasTwo={false} ></FormComponent> :<CircularProgress />
                               } 
                                <br />

                                {
                                dataRowMiRed2 ?  <FormComponent dataRow={dataRowMiRed2}  hasData={true}  currentReport={"RESUMEN PROMOTOR SELECCIONADO"} hasTotal={true} hasTwo={true} ></FormComponent> :<CircularProgress />
                               } 
                               
                                <br />

                            </div> : <></>}
                        </Grid> : <></>

                    }

                </Grid>

            </Container>

        );

    }
    export default ReportsPage;