import React, { useEffect, useState } from 'react';
// import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
// import Drawer from '@material-ui/core/Drawer';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import List from '@material-ui/core/List';
// import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { ClientModel, PeriodModel, AgentPromotorModel, ClientPromotorModel, ReportModel, UserModel, WalletModel, ReportWalletLevelModel, ReportModelToShow, EmployeeModel } from '../Models/Models';
import { fetchViewListPeriods, fetchViewPersonalPurchases, fetchViewByWallet, fetchViewClient, fetchViewAgent, fetchViewByPeriodWallet, fetchViewSelectedPromotor, fetchViewRegisterClient, fetchViewListEmployeeChilds } from '../Services/Services';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TreeViewRed from '../Components/TreeView';

import TextField from '@material-ui/core/TextField';
const useStyles = makeStyles(theme => ({
    root: {
        width: 150,
        maxWidth: 400,
        backgroundColor: theme.palette.background.paper,
        fontSize: "20px"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    table: {
        minWidth: 650,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    }, labelRoot: {
        fontSize: 15,
        color: "black",
        "&$labelFocused": {
            color: "purple"
        }, marginLeft: "15px"
    },
    input: { fontSize: 25, },
    labelFocused: {}
}));


const
    RegisterPage: React.FC = () => {


        const classes = useStyles();

        const [age, setAge] = React.useState('');

        const [firstName, setFirstName] = useState('');
        const [lastName, setLastName] = useState('');
        const [lastName2, setLastName2] = useState('');
        const [email, setEmail] = useState('');
        const [telephone, setTelephone] = useState('');
        const [cellphone, setCellphone] = useState('');
        const [address, setAddress] = useState('');
        const [city, setCity] = useState('Cochabamba');
        const [nit, setNit] = useState('');
        const [obs, setObs] = useState('');
        const [type, setType] = useState('Agente Promotor');
        const [parent, setParent] = useState('');
        const [parentName, setParentName] = useState('');
        const [openCheckBoxData, setOpenCheckBoxData] = React.useState(false);
        const [openCheckBoxData2, setOpenCheckBoxData2] = React.useState(false);
        const [EmployeeList, setEmployeeList] = React.useState<EmployeeModel>();
        const [currentUserLogged, setCurrentUserLogged] = React.useState<UserModel>();
        const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
            setAge(event.target.value as string);
        };


        useEffect(() => {


        }, []);




        useEffect(() => {

            getDataFromUser()
        }, []);

        async function getDataFromUser() {
            var guardado = sessionStorage.getItem('user');
            if (guardado == null || guardado == undefined) {

            } else {
                let currentUser: UserModel = JSON.parse(guardado ? guardado : "");
                setCurrentUserLogged(currentUser);
                let currentEmployeee: EmployeeModel = await fetchViewListEmployeeChilds(currentUser.EmployeeId.toString());
                setEmployeeList(currentEmployeee);
                console.log(currentEmployeee.Children.length)

            }

        }



        function Alert(props: any) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }

        function validarNumber(str: any) {
            if (str === "") { return false }
            var pattern = /^\d+$/;
            return pattern.test(str);  // returns a boolean
        }
        function validarEmail(str: any) {
            var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            return pattern.test(str);  // returns a boolean
        }
        const handleClickData = () => {
            setOpenCheckBoxData(true);
        };
        const handleCloseData = (event: any, reason: any) => {
            if (reason === 'clickaway') {
                return;
            }

            setOpenCheckBoxData(false);
        };
        const handleClickData2 = () => {
            setOpenCheckBoxData2(true);
        };
        const handleCloseData2 = (event: any, reason: any) => {
            if (reason === 'clickaway') {
                return;
            }

            setOpenCheckBoxData2(false);
        };

        async function registerClient() {

            let client: ClientModel = {

                firstName: firstName,
                lastName1: lastName,
                lastName2: lastName2,
                email: email,
                address: address,
                latitude: 0,
                longitude: 0,
                mobile: cellphone,
                name: firstName,
                password: "",
                phone: telephone,
                ciudad: city,
                billName: "",
                billNit: nit,
                documentNumber: nit,
                localizationId: 1,
                observaciones: obs,
                parentId: +parent,
                tipo: type



            }

            console.log("clientttttttttttt", client)
            if (firstName === "" || lastName === "" || lastName2 === "" || email === "" || address === "" || cellphone === "" || nit === "" || parent=="") {
                handleClickData();


            } else {
                let result: string = await fetchViewRegisterClient(client);
                console.log("clientttttttttttt", result)
                handleClickData2();                
                
                setFirstName("");
                setLastName("");
                setLastName2("");
                setEmail("");
                setAddress("");
                setCellphone("");
                setNit("");
                setParent("");
                setObs("");
                setTelephone("");                

            }
        }

        return (
            <Container fixed style={{ backgroundColor: "#DCDCDC", marginTop: "-20px" }}>
                <Grid container >

                    <h1>Registro</h1>

                    <section id="contact-form" style={{ width: "100%" }} >
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <div className="block">
                                        <form>
                                            <div className="form-group" >
                                                <TextField className="form-control" id="standard-basic" label="Nombre" error={firstName === ""} onInput={e => setFirstName((e.target as HTMLInputElement).value)}
                                                    style={{ marginLeft: "5px", fontSize: "15px", width: "100%" }} InputProps={{ disableUnderline: true }} inputProps={{ style: { fontSize: 15, marginLeft: "20px" } }} InputLabelProps={{
                                                        classes: {
                                                            root: classes.labelRoot,
                                                            focused: classes.labelFocused
                                                        }
                                                    }} />
                                            </div>
                                            <div className="form-group">
                                                <TextField className="form-control" id="standard-basic" label="Primer Apellido" error={lastName === ""} value={lastName} onInput={e => setLastName((e.target as HTMLInputElement).value)}
                                                    style={{ marginLeft: "5px", fontSize: "15px", }} InputProps={{ disableUnderline: true }} inputProps={{ style: { fontSize: 15, marginLeft: "20px" } }} InputLabelProps={{
                                                        classes: {
                                                            root: classes.labelRoot,
                                                            focused: classes.labelFocused
                                                        }
                                                    }} />
                                            </div>
                                            <div className="form-group">
                                                <TextField className="form-control" id="standard-basic" label="Segundo Apellido" error={lastName2 === ""} value={lastName2} onInput={e => setLastName2((e.target as HTMLInputElement).value)}
                                                    style={{ marginLeft: "5px", fontSize: "15px", }} InputProps={{ disableUnderline: true }} inputProps={{ style: { fontSize: 15, marginLeft: "20px" } }} InputLabelProps={{
                                                        classes: {
                                                            root: classes.labelRoot,
                                                            focused: classes.labelFocused
                                                        }
                                                    }} />
                                            </div>
                                            <div className="form-group">
                                                <TextField className="form-control" id="standard-basic" label="Carnet de Identidad / Nit" error={validarNumber(nit) == false} value={nit} onInput={e => setNit((e.target as HTMLInputElement).value)}
                                                    style={{ marginLeft: "5px", fontSize: "15px", }} InputProps={{ disableUnderline: true }} inputProps={{ style: { fontSize: 15, marginLeft: "20px" } }} InputLabelProps={{
                                                        classes: {
                                                            root: classes.labelRoot,
                                                            focused: classes.labelFocused
                                                        }
                                                    }} />
                                            </div>
                                            <div className="form-group">
                                                <TextField className="form-control" id="standard-basic" label="Email" error={validarEmail(email) === false} value={email} onInput={e => setEmail((e.target as HTMLInputElement).value)}
                                                    style={{ marginLeft: "5px", fontSize: "15px", }} InputProps={{ disableUnderline: true }} inputProps={{ style: { fontSize: 15, marginLeft: "20px" } }} InputLabelProps={{
                                                        classes: {
                                                            root: classes.labelRoot,
                                                            focused: classes.labelFocused
                                                        }
                                                    }} />
                                            </div>
                                            <div className="form-group">
                                                <TextField className="form-control" id="standard-basic" label="Celular" error={validarNumber(cellphone) == false} value={cellphone} onInput={e => setCellphone((e.target as HTMLInputElement).value)}
                                                    style={{ marginLeft: "5px", fontSize: "15px", }} InputProps={{ disableUnderline: true }} inputProps={{ style: { fontSize: 15, marginLeft: "20px" } }} InputLabelProps={{
                                                        classes: {
                                                            root: classes.labelRoot,
                                                            focused: classes.labelFocused
                                                        }
                                                    }} />
                                            </div>
                                            <div className="form-group">
                                                <TextField className="form-control" id="standard-basic" label="Telefono Fijo" value={telephone} onInput={e => setTelephone((e.target as HTMLInputElement).value)}
                                                    style={{ marginLeft: "5px", fontSize: "15px", }} InputProps={{ disableUnderline: true }} inputProps={{ style: { fontSize: 15, marginLeft: "20px" } }} InputLabelProps={{
                                                        classes: {
                                                            root: classes.labelRoot,
                                                            focused: classes.labelFocused
                                                        }
                                                    }} />
                                            </div>
                                            <div className="form-group">
                                                <TextField className="form-control" id="standard-basic" label="Direccion" error={address === ""} value={address} onInput={e => setAddress((e.target as HTMLInputElement).value)}
                                                    style={{ marginLeft: "5px", fontSize: "15px", }} InputProps={{ disableUnderline: true }} inputProps={{ style: { fontSize: 15, marginLeft: "20px" } }} InputLabelProps={{
                                                        classes: {
                                                            root: classes.labelRoot,
                                                            focused: classes.labelFocused
                                                        }
                                                    }} />
                                            </div>
                                            <div className="form-group">
                                                <TextField className="form-control" id="standard-basic" label="Observaciones"  value={obs} onInput={e => setObs((e.target as HTMLInputElement).value)}
                                                    style={{ marginLeft: "5px", fontSize: "15px", }} InputProps={{ disableUnderline: true }} inputProps={{ style: { fontSize: 15, marginLeft: "20px" } }} InputLabelProps={{
                                                        classes: {
                                                            root: classes.labelRoot,
                                                            focused: classes.labelFocused
                                                        }
                                                    }} />
                                            </div>
                                            <div className="form-group">

                                                <FormControl className="form-control">
                                                    <InputLabel className={classes.labelRoot} id="demo-simple-select-label">Ciudad</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={city}
                                                        onChange={e => setCity((e.target as HTMLInputElement).value)}
                                                        disableUnderline
                                                        style={{ marginLeft: "20px", fontSize: "15px" }}

                                                    >
                                                        <MenuItem value={"Cochabamba"}>Cochabamba</MenuItem>
                                                        <MenuItem value={"Santa Cruz"}>Santa Cruz</MenuItem>
                                                        <MenuItem value={"La Paz"}>La Paz</MenuItem>
                                                        <MenuItem value={"Potosi"}>Potosi</MenuItem>
                                                        <MenuItem value={"Oruro"}>Oruro</MenuItem>
                                                        <MenuItem value={"Pando"}>Pando</MenuItem>
                                                        <MenuItem value={"Beni"}>Beni</MenuItem>v
                    <MenuItem value={"Tarija"}>Tarija</MenuItem>
                                                        <MenuItem value={"Chuquisaca"}>Chuquisaca</MenuItem>

                                                    </Select>
                                                </FormControl>


                                            </div>
                                            <div className="form-group">

                                                <FormControl className="form-control">
                                                    <InputLabel className={classes.labelRoot} id="demo-simple-select-label">Tipo</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={type}
                                                        onChange={e => setType((e.target as HTMLInputElement).value)}
                                                        disableUnderline
                                                        style={{ marginLeft: "20px", fontSize: "15px" }}

                                                    >
                                                        <MenuItem value={"AGENTE PROMOTOR"}>AGENTE PROMOTOR</MenuItem>
                                                        <MenuItem value={"CLIENTE PROMOTOR"}>CLIENTE PROMOTOR</MenuItem>

                                                    </Select>
                                                </FormControl>

                                            </div>
                                            <div className="form-group">


                                                <FormControl className="form-control">
                                                    <InputLabel className={classes.labelRoot} id="demo-simple-select-label">Referido de:</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={parentName}
                                                        onChange={e => setParentName((e.target as HTMLInputElement).value)}
                                                        disableUnderline
                                                        style={{ marginLeft: "20px", fontSize: "15px" }}

                                                    >
                                                        <MenuItem value={currentUserLogged?.EmployeeId.toString()} onClick={()=>{setParent(currentUserLogged?.EmployeeId.toString()?currentUserLogged?.EmployeeId.toString():'')}}>{currentUserLogged?.UserName}</MenuItem>
                                                        {EmployeeList && EmployeeList.Children.map(m => {
                                                            return <MenuItem value={m.Id.toString()}  onClick={()=>{setParent(m.Id.toString())}}>{m.CompleteName}</MenuItem>



                                                        })}



                                                    </Select>
                                                </FormControl>



                                            </div>
                       
                                            <Button variant="contained" color="primary" disableElevation onClick={() => { registerClient() }}>
                                                Registrar
</Button>

                                        </form>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="block">
                                        <form>


                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>



                </Grid>
                <Snackbar style={{ width: "50%" }} open={openCheckBoxData} autoHideDuration={4000} onClose={handleCloseData}>
                    <Alert style={{ width: "50%", fontSize: "14px" }} onClose={handleCloseData} severity="error">
                        Por favor llene los datos correctamente
                                                     </Alert>
                </Snackbar>

                <Snackbar style={{ width: "50%" }} open={openCheckBoxData2} autoHideDuration={4000} onClose={handleCloseData2}>
                    <Alert style={{ width: "50%", fontSize: "14px" }} onClose={handleCloseData2} severity="success">
                        Registro Realizado Correctamente
                                                     </Alert>
                </Snackbar>
            </Container>

        );

    }
export default RegisterPage;