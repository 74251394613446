import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import CardItem from './CardItem'
import {ProductType, Cart} from '../Models/Models';
import { fetchViewListProducts } from '../Services/Services'; 

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width:"93%",
      marginLeft:"50px"
    },
    root2: {
      flexGrow: 1,
      width:"90%",
      marginLeft:"10px"
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));
  


interface RowProps{

    ProductTypeDetail : ProductType;
    SetCurrentCart: Function;
    CurrentCart:Cart;
    HandleClick : Function;
    onlySection:Boolean;
    LocalizationId:String;
    EmployeeType:String;

}

const Row: React.FC<RowProps> = ({ProductTypeDetail,SetCurrentCart,CurrentCart,HandleClick,onlySection,LocalizationId,EmployeeType}) => {
    const classes = useStyles();

  
   
    let [currentProductList,setCurrentProductList] = useState([]);

    useEffect(() => {

      const getProductTypes = async () => {
        console.log()
        let List : any =   await fetchViewListProducts(ProductTypeDetail.ProductTypeId.toString(),LocalizationId,EmployeeType);
        setCurrentProductList(List);
        // console.log("<-->",List);
      };
    
      getProductTypes();
     
    }, [ProductTypeDetail,LocalizationId,EmployeeType]);

  




  return (
    <div className={onlySection?classes.root2:classes.root}>

      <Grid style={{ backgroundColor: "", left: "0" }} container spacing={2}>
        {
          currentProductList.length > 0 ?
            <Grid style={onlySection?{ marginLeft: "0px" }:{ marginLeft: "50px" }} item lg={12} md={12} xs={12}>
              <h1 >{ProductTypeDetail.ProductTypeName}</h1>

            </Grid>
            : <></>
        }
        {/* {
          currentProductList.length > 0 ?
            <Grid style={{ marginLeft: "50px" }} item lg={12} md={12} xs={12}>
              <h2 >{ProductTypeDetail.ProductTypeDescription}</h2>
            </Grid>
            : <></>
        } */}
        {
          currentProductList.length > 0 ?
            currentProductList.map((item, index) => {
              return <Grid key={index} style={{ marginLeft: "0px" }} item lg={3} md={4} xs={12}>
                <CardItem 
                ProductDetailItem={item} 
                CurrentCart={CurrentCart}
                SetCurrentCart={SetCurrentCart}
                HandleClick={HandleClick}
                
                ></CardItem>
              </Grid>
            }) : <></>
        }
      </Grid>

    </div>

  );
}



export default Row;
