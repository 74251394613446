import React, { useState } from 'react';

import './App.css';
import LoginForm from './Components/LoginForm';
import ReportsPage from './Pages/ReportsPage';
import RegisterPage from './Pages/RegisterPage';
import ProductsPage from './Pages/ProductsPage';
import { Cart } from './Models/Models';
import OrdersPage from './Pages/OrdersPage';
import CheckoutPage from './Pages/CheckoutPage';
import HomePage from './Pages/HomePage';
const App2 :React.FC= () => {
  let cart : Cart ={UserName:"Nick",UserNit:"11010110",ProductCartDetail:[]}
  const [currentState,setCurrentState] = useState("");
  let [currentCart,setCurrentCart] = useState(cart);
  


  setInterval(function(){
    var modalValue = sessionStorage.getItem( 'currentView' );

    if( modalValue ){
        // use the value
        // remove the value when you are done so that this code doesn't run every time
       setCurrentState(modalValue)
        // sessionStorage.setItem( 'key', modalValue );
    }else{
      setCurrentState("inicio")

    }
}, 100 );
  return (
    <div className="App2">
      <LoginForm></LoginForm>
      {/* <ReportsPage></ReportsPage> */}
      {currentState=="inicio"?
     <HomePage></HomePage>:<></> } 
      {currentState=="reportes"?
     <ReportsPage></ReportsPage>:<></> } 
  {currentState=="registro"?
     <RegisterPage></RegisterPage>:<></> } 

{currentState=="productos"?
     <ProductsPage CurrentCart={currentCart} SetCurrentCart = {setCurrentCart} ></ProductsPage>:<></> } 
{currentState=="pedidos"?
     <OrdersPage  CurrentCart={currentCart} ></OrdersPage>:<></> } 
{currentState=="check"?
     <CheckoutPage CurrentCart={currentCart} SetCurrentCart = {setCurrentCart}  ></CheckoutPage>:<></> } 

    </div>
  );
}

export default App2;
