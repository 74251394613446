import React, { useEffect, useState } from 'react';
import ReactPlayer from "react-player"
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import { UserModel } from '../Models/Models';
const HomePage: React.FC = () => {


  const [currentUser, setCurrentUser] = useState<UserModel>();
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const [respon, setRespon] = useState<boolean>(false);



  useEffect(() => {
    let cookieUser: any = sessionStorage.getItem("user");
    let currentUserlogged: UserModel = JSON.parse(cookieUser);

    if (currentUserlogged) {

      setIsLogged(true);
    }


  }, []);

  function setView(value: any) {


    sessionStorage.setItem('currentView', value);

  }


  function myFunction2(value: any) {
    sessionStorage.setItem("cart", value)
  }
  function myFunction3(value: any) {

    myFunction2(value)
    var aux = sessionStorage.getItem("cart2");
    if (aux == undefined || aux == null || aux == "unhover") {
      sessionStorage.setItem("cart2", value)

    } else if (aux == "hover") { sessionStorage.setItem("cart2", "unhover") }
    else { { sessionStorage.setItem("cart2", "unhover") } }

  }
  // background:'url(../img/Fondo.jpg)',
  // background-size:cover,

  // background-attachment: fixed,
  // background-position: 0% 20%,
  // padding: 280px 0 450px 0,
  // position: relative

  const { height, width } = useWindowDimensions();

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  useEffect(() => {

    if (width < 767) {
      setRespon(true);
    } else { setRespon(false); }
  }, [width]);

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }
  return (
    <div id="container " style={{ marginTop: "-20px" }}>
      <section id="slider" style ={respon?{
        backgroundImage:"url(../img/Fondo.jpg)",
         backgroundSize:"cover",
        backgroundAttachment:"fixed",
        backgroundPosition: "70% 20%",
        padding: "280px 0 100px 0",
        position: "relative"
      }:{
        backgroundImage:"url(../img/Fondo.jpg)",
         backgroundSize:"cover",
        backgroundAttachment:"fixed",
        backgroundPosition: "0% 20%",
        padding: "280px 0 450px 0",
        position: "relative"
      }}>
         
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-md-offset-2">
              <div className="block">
                <h1 className="animated fadeInUp">Bienvenido a tu portal web Montecristo</h1>
                {isLogged ? <p className="animated fadeInUp">Sigamos creciendo juntos! </p> : <></>}
                {isLogged ? <></> :                  
                        <a className="btn btn-view-works" style={{ backgroundColor: "gray", color: "white" }} onClick={() => { myFunction3('hover') }}   >Iniciar Sesión</a>
                     
                  }
                  <br></br>
                {isLogged ? <></> : 
                        <a target="_blank" href="https://www.montecristobolivia.com/" className="btn btn-view-works" style={{ backgroundColor: "gray", color: "white" }} >Mas de Montecristo</a>
                      }

                {/* <p className="animated fadeInUp">Sigamos creciendo juntos! </p>
               
                <a className="btn btn-view-works" onClick={() => { setView('productos') }}>Productos</a> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="intro">
        <div className="container">
          <div className="row">
            <div className="col-md-7 col-sm-12">
              <div className="block">
                <div className="section-title">
                  <h2>Nuevo portal web para promotores!</h2>
                  <p>Descubre todas las herramientas de la nueva web para promotores en este video tutorial.
Esta página te permitirá estar informado y realizar las actividades diarias como promotor Montecristo de forma digital.</p>
                </div>
                
              </div>
            </div>

            <div className="col-md-5 col-sm-12">
              <div className="block">
                <ReactPlayer width={"100%"}
                  url="https://youtu.be/GHuTdbbqkOg"
                />
              </div>
            </div>
            {/* <div className="col-md-5 col-sm-12">
          <div className="block">

    <ReactPlayer
        url="https://www.youtube.com/watch?v=ekzHIouo8Q4"
      />
          </div>
        </div> */}
          </div>
        </div>
      </section>



      <section id="feature" style ={respon?{
      backgroundPosition: "0% 0%"
      }:{
        backgroundPosition: "0% 0%"
      }} >
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-md-offset-3">
              <h2>Realiza tu pedido disfrutando de un 30% de descuento. </h2>
              {/* <p style={{color:"black"}}>Maecenas faucibus mollis interdum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Fusce
            dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.
          </p> */}
              {/* <p style={{color:"black"}} >Maecenas faucibus mollis interdum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Fusce
            dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.
          </p>
          <p style={{color:"black"}}>Maecenas faucibus mollis interdum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Fusce
            dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.
          </p> */}
              <a className="btn btn-view-works" onClick={() => { setView('productos') }}>Productos</a>
            </div>
          </div>
        </div>
      </section>


      <section id="service">
        <div className="container">
          <div className="row">
            <div className="section-title">
              <h2>Nuestros Servicios</h2>
              {/* <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the
            blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics</p> */}
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-6 col-md-4" onClick={() => { setView('reportes') }}>
              <div className="service-item">
                <img height="80px" width="80px" src="img/pedidos.png" alt="IMG" />
                <h4>Reporte de Ventas</h4>
                <p>Conoce tus compras personales acumuladas por mes y año de tu red.</p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4" onClick={() => { setView('reportes') }}>
              <div className="service-item">
                <img height="80px" width="80px" src="img/RED.png" alt="IMG" />
                <h4>Tu red</h4>
                <p>Conoce las compras de tu red y la actividad de todos tus niveles. </p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4" onClick={() => { setView('registro') }}>
              <div className="service-item">
                <img height="80px" width="80px" src="img/REGISTROPROMOTORES.png" alt="IMG" />
                <h4>Registro de Promotores</h4>
                <p>Registra tus promotores para agilizar la inscripción de los mismos. </p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4" onClick={() => { setView('registro') }}>
              <div className="service-item">
                <img height="80px" width="80px" src="img/REGISTROCLIENTES.png" alt="IMG" />
                <h4>Registro de Clientes </h4>
                <p>Registra clientes referidos que se habiliten automáticamente para que puedas ingresarles un pedido. </p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4" onClick={() => { setView('reportes') }}>
              <div className="service-item">
                <img height="80px" width="80px" src="img/BILLETERAMONTECRISTO.png" alt="IMG" />
                <h4>Billetera Montecristo</h4>
                <p>Verifica los abonos y retiros mensuales de billetera Montecristo a tu cuenta digital. </p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4" onClick={() => { setView('productos') }}>
              <div className="service-item">
                <img height="80px" width="80px" src="img/REGISTROVENTAS.png" alt="IMG" />
                <h4>Pedidos</h4>
                <p>realiza tus pedidos desde cualquier departamentos de Bolivia, también puedes pedir para unos de tus referidos. </p>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section id="call-to-action">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block">
                <h2>Comunícate con atención al cliente si tienes alguna duda de cómo utilizar esta herramienta.</h2>
                {/* <p>Read more about what we do and our philosophy of design. Judge for yourself The work and results we’ve
              achieved for other clients, and meet our highly experienced Team who just love to design.</p> */}
                <a target="_blank" href="https://api.whatsapp.com/send?phone=+59163396234" ><WhatsAppIcon style={{ height: "100px", width: "100px" }}></WhatsAppIcon></a>
                {/* <a className="btn btn-default btn-call-to-action" href="#">Tell Us Your Story</a> */}
              </div>
            </div>
          </div>
        </div>
      </section>


      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* <div className="footer-manu">
            <ul>
              <li><a target="_blank" href="https://api.whatsapp.com/send?phone=+59163396234"> <WhatsAppIcon style={{height:"100px",width:"100px"}}></WhatsAppIcon></a> </li>
              <li><a  target="_blank" href="https://www.instagram.com/montecristobolivia/"> <InstagramIcon style={{height:"100px",width:"100px"}}></InstagramIcon></a> </li>
              <li><a target="_blank" href="https://www.facebook.com/montecristobolivia"> <FacebookIcon style={{height:"100px",width:"100px"}}></FacebookIcon> </a> </li>
     
            </ul>
          </div> */}
              <div className="col-md-4"><a target="_blank" href="https://api.whatsapp.com/send?phone=+59163396234"> <WhatsAppIcon style={{ height: "100px", width: "100px" }}></WhatsAppIcon></a></div>
              <div className="col-md-4"><a target="_blank" href="https://www.instagram.com/montecristobolivia/"> <InstagramIcon style={{ height: "100px", width: "100px" }}></InstagramIcon></a></div>
              <div className="col-md-4"><a target="_blank" href="https://www.facebook.com/montecristobolivia"> <FacebookIcon style={{ height: "100px", width: "100px" }}></FacebookIcon> </a></div>

              {/* <div >
            <ul>
              <li><a target="_blank" href="https://api.whatsapp.com/send?phone=+59163396234"> <WhatsAppIcon style={{height:"100px",width:"100px"}}></WhatsAppIcon></a> </li>
              <li><a  target="_blank" href="https://www.instagram.com/montecristobolivia/"> <InstagramIcon style={{height:"100px",width:"100px"}}></InstagramIcon></a> </li>
              <li><a target="_blank" href="https://www.facebook.com/montecristobolivia"> <FacebookIcon style={{height:"100px",width:"100px"}}></FacebookIcon> </a> </li>
     
            </ul>
          </div> */}
              <p>Copyright &copy; Crafted by <a href="https://dcrazed.com/">Dcrazed</a>.</p>
            </div>
          </div>
        </div>
      </footer>

      {/* <section id="testimonial">
    <div className="container">
      <div className="row">
        <div className="section-title text-center">
          <h2>Fun Facts About Us</h2>
          <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the
            blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="block">
            <ul className="counter-box clearfix">
              <li>
                <div className="block">
                  <i className="ion-ios-chatboxes-outline"></i>
                  <h4 className="counter">20000</h4>
                  <span>Cups Of Coffee</span>
                </div>
              </li>
              <li>
                <div className="block">
                  <i className="ion-ios-glasses-outline"></i>
                  <h4 className="counter">20000</h4>
                  <span>Cups Of Coffee</span>
                </div>
              </li>
              <li>
                <div className="block">
                  <i className="ion-ios-compose-outline"></i>
                  <h4 className="counter">20000</h4>
                  <span>Cups Of Coffee</span>
                </div>
              </li>
              <li>
                <div className="block">
                  <i className="ion-ios-timer-outline"></i>
                  <h4 className="counter">20000</h4>
                  <span>Cups Of Coffee</span>
                </div>
              </li>

            </ul>
          </div>
        </div>
        <div className="col-md-6">
          <div className="testimonial-carousel">
            <div id="testimonial-slider" className="owl-carousel">
              <div>
                <img src="img/cotation.png" alt="IMG"/>
                <p>"This Company created an e-commerce site with the tools to make our business a success, with
                  innovative ideas we feel that our site has unique elements that make us stand out from the crowd."</p>
                <div className="user">
                  <img src="img/item-img1.jpg" alt="Pepole"/>
                  <p><span>Rose Ray</span> CEO</p>
                </div>
              </div>
              <div>
                <img src="img/cotation.png" alt="IMG"/>
                <p>"This Company created an e-commerce site with the tools to make our business a success, with
                  innovative ideas we feel that our site has unique elements that make us stand out from the crowd."</p>
                <div className="user">
                  <img src="img/item-img1.jpg" alt="Pepole"/>
                  <p><span>Rose Ray</span> CEO</p>
                </div>
              </div>
              <div>
                <img src="img/cotation.png" alt="IMG"/>
                <p>"This Company created an e-commerce site with the tools to make our business a success, with
                  innovative ideas we feel that our site has unique elements that make us stand out from the crowd."</p>
                <div className="user">
                  <img src="img/item-img1.jpg" alt="Pepole"/>
                  <p><span>Rose Ray</span> CEO</p>
                </div>
              </div>
              <div>
                <img src="img/cotation.png" alt="IMG"/>
                <p>"This Company created an e-commerce site with the tools to make our business a success, with
                  innovative ideas we feel that our site has unique elements that make us stand out from the crowd."</p>
                <div className="user">
                  <img src="img/item-img1.jpg" alt="Pepole"/>
                  <p><span>Rose Ray</span> CEO</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> */}
    </div>

  );
}



export default HomePage;
