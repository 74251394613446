import React, { useEffect, useState }  from 'react';
import { Cart, ClientModel, Order, OrderReceive } from '../Models/Models';
import { fetchViewListOrdersDetail } from '../Services/Services';
import Base64 from '../Services/functions';
  
interface OrdersPage {
  CurrentCart: Cart;
}

const OrdersPage :React.FC<OrdersPage>= ({CurrentCart}) => {

    let [currentUserLogged,setCurrentUserLogged] = useState<any>();
    let [currentOrdersUser,setCurrentOrder] = useState<Array<OrderReceive>>();


   

    const [total, settotal] = useState(0); 

    const CryptoJS = require('crypto-js');

    function  encrypt(text:string){
      return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
    };
    
    function decrypt(text:string) {
      return CryptoJS.enc.Base64.parse(text).toString(CryptoJS.enc.Utf8);
    };

    useEffect(() => {
  
      let cookieUser:any = sessionStorage.getItem("user");
      console.log("cookieUser",cookieUser);
      let currentUser:ClientModel = JSON.parse(cookieUser);
      console.log("currentUser",currentUser);
      if(currentUser!== undefined && currentUser!== null ){
        setCurrentUserLogged(currentUser);
        getOrderDetail();

      }else{
       
      }
      
 
      // let currentOrder:Order = JSON.parse(cookieUser);


    
    }, [CurrentCart]);


    function reload(){
      let cookieUser:any = sessionStorage.getItem("user");
      console.log("cookieUser",cookieUser);
      let currentUser:ClientModel = JSON.parse(cookieUser);
      console.log("currentUser",currentUser);
      if(currentUser!== undefined && currentUser!== null ){
        setCurrentUserLogged(currentUser);
        getOrderDetail();

      }else{
       
      }
      
    }
    async function getOrderDetail(){
      let cookieUser:any = sessionStorage.getItem("user");
      let currentUser:any = JSON.parse(cookieUser);
      console.log("currentUser",currentUser);
      if(currentUser!== undefined && currentUser!== null ){
        let order : Array<OrderReceive> = await fetchViewListOrdersDetail(currentUser.EmployeeId)
        console.log("order",order)
        if(order)
        setCurrentOrder(order)
      }else{
       
      }

}
  
    function totalOrder(order :OrderReceive){
        let aux :any = 0;
        // for(let i of order.items){

        //     aux = aux+ (+i.ProductSalePrice*+i.ProductQuantity);
        //  } 
         return aux;
    }
    function totalQuantity(order:OrderReceive){
        let aux :any = 0;
        // for(let i of order.items){

        //     aux = aux+ (i.ProductQuantity);
        //  } 
         return aux;
    }

 

  return (
    <div id="container" style={{backgroundColor:"#DCDCDC" ,marginTop:"-20px"}}>
    <div className="container">

    
      <div className="row">

        <div id="content" className="col-sm-12">
        <h1 className="title">Historial de Pedidos</h1>
            <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
           
              <td className="text-center">Order ID</td>
              {/* <td className="text-center">Cantidad</td> */}
              {/* <td className="text-center">Estatus</td> */}
              <td className="text-center">Fecha</td>
              <td className="text-right">Total</td>
              <td></td>
            </tr>
          </thead>
          <tbody>

              { 
                 currentOrdersUser? currentOrdersUser.map((item, index) => {
                      return             <tr>
                 
                 <td className="text-center">{item.OrderNum}</td>
                 {/* <td className="text-center">{totalQuantity(item)}</td> */}
                 {/* <td className="text-center">{item.State}</td> */}
                 <td className="text-center">{item.OrderDate}</td>
                 <td className="text-right">Bs. {item.Total}</td>
                      <td className="text-center"><a style={{marginTop:'-8px'}} className="btn btn-info" title="" data-toggle="tooltip"  data-original-title="View"><i className="fa fa-eye"></i></a></td>
                
                    </tr>
                  }):<></>

              } 
               
            
                      </tbody>
        </table>
      </div>               
        </div>

      </div> 
    </div>
</div>

  );
}



export default OrdersPage;