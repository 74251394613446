import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { ReportModel } from '../Models/Models';

import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';



const useStyles = makeStyles(theme => ({
    root2: {
        '& > *': {
            marginTop: theme.spacing(2),
        }
    },
    root: {
        width: 150,
        maxWidth: 400,
        backgroundColor: theme.palette.background.paper,
        fontSize: "1.5rem"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    table: {
        // minWidth: 650,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 50,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

}));


interface FormComponentProps {
    dataRow: Array<any>;
    currentReport: string;
    hasTotal: boolean;
    hasTwo: boolean;
    hasData:boolean;
}


const FormComponent: React.FC<FormComponentProps> = ({ dataRow, currentReport, hasTotal, hasTwo,hasData }) => {
    const classes = useStyles();
    const [currentPagination, setCurrentPagination] = React.useState<number>(10);
    const [dataRowsToShowPromotoresReferidos, setDataRowsToShowPromotoresReferidos] = React.useState<Array<any>>([]);
    const [currentPage, setCurrentPage] = React.useState<number>(0);
    const [currentProps, setCurrentProps] = React.useState<Array<string>>([]);
    const [total, setTotal] = React.useState<number>(0);
    const [subTotal, setSubTotal] = React.useState<number>(0);

    const [loading, setLoading] = React.useState<boolean>(false);





    useEffect(() => {

        // if( dataRow && dataRow[0]){
        //     Object.getOwnPropertyNames(dataRow[0].array.forEach((element: any) => {
        // console.log("->",element)    
        // }));
        // }
        let auxArray: Array<string> = [];
        if (dataRow && dataRow[0]) {
            for (const [key, value] of Object.entries(dataRow[0])) {
                auxArray.push(key);
            }
        }
        // console.log("my aux", auxArray);
        setCurrentProps(auxArray);


        



    }, [dataRow]);

    
    useEffect(() => {
        setLoading(true)
        setTimeout( () => { setLoading(false) }, 10000 );


    }, [dataRow]);


    useEffect(() => {

        let arrayAux1: Array<number>=[];

    if(currentReport==="RESUMEN PROMOTORES REFERIDOS"){

        for(let i = 0; i<dataRow.length;i++){






        }








    }   

    }, [dataRow]);



    useEffect(() => {

        let newDataRow: Array<any> = dataRow.slice(currentPage * currentPagination, currentPage * currentPagination + currentPagination);

        // console.log("newDataRow", dataRow);
        let subAuxTotal: number = 0;
       
        for (let i = 0; i < newDataRow.length; i++) {


            if (newDataRow[i].BillAmount) {
                subAuxTotal += newDataRow[i].BillAmount
            } else if (newDataRow[i].Subtotal) {
                subAuxTotal += newDataRow[i].Subtotal
            } else {
                subAuxTotal += newDataRow[i].CP;
            }




            //     newDataRow[i].BillAmount ?
            //     subAuxTotal += newDataRow[i].BillAmount :
            //    // newDataRow[i].Subtotal ?
            //     subAuxTotal += +newDataRow[i].Subtotal
            //     //:subAuxTotal += newDataRow[i].CP;

        }
        let auxTotal: any = 0;
        for (let k = 0; k < dataRow.length; k++) {

            dataRow[k].BillAmount ?
                auxTotal += dataRow[k].BillAmount :

                //dataRow[k].Subtotal?
                auxTotal += +dataRow[k].Subtotal
                //  : auxTotal += dataRow[k].CP

                ;
        }
        // console.log("newDataRow", subAuxTotal);
        // console.log("newDataRow", auxTotal);
        let n:number = +subAuxTotal.toFixed(2);
        var nn:number = +auxTotal.toFixed(2);
        if(n === NaN){
            setSubTotal(0);
        }else{
            setSubTotal(+n);
        } 
        if(nn === NaN){
            setTotal(0);
        }else{
            setTotal(+nn);
        }
        
       
    
       

    }, [currentPage, dataRow, currentPagination]);






    function getByProperty(row: any, title: string) {

        switch (title) {
            case "BillDate":
                return row.BillDate;

            case "BillNumber":
                return row.BillNumber;

            case "Source":
                return row.Source;

            case "BillAmount":
                return row.BillAmount;

            case "BillCode":
                return row.BillCode;

            case "BranchName":
                return row.BranchName;

            case "Employee":
                return row.Employee;

            case "Amount":
                return row.Amount;

            case "SumChild2":
                return row.SumChild2;

            case "Subtotal":
                return row.Subtotal;
            case "Parent":
                return row.Parent;
            case "Level":
                return row.Level;
            case "CP":
                return row.CP;

            case "AccountId":
                return row.AccountId;

            case "AccountDetailId":
                return row.AccountDetailId;

            case "AccountDetailType":
                return row.AccountDetailType;

            case "AccountDetailDate":
                return row.AccountDetailDate;
            case "AccountDetailAmmount":
                return row.AccountDetailAmmount;
            case "AccountDetailTask":
                return row.AccountDetailTask;
            case "AccountDetailNetAmount":
                return row.AccountDetailNetAmount;

            case "CreatedBy":
                return row.CreatedBy;

            case "AccountDetailDescription":
                return row.AccountDetailDescription;
            case "Deposit":
                return row.Deposit;
            case "Withdrawal":
                return row.Withdrawal;
            case "Balance":
                return row.Balance;
            case "PCN":
                return row.PCN;
            case "Wallet":
                return row.Wallet;

        }
        return "";


    }




    function renamerColumn(title: string) {

        switch (title) {
            case "BillDate":
                return "Fecha";

            case "BillNumber":
                return "BillNumber";

            case "Source":
                return "Origen";

            case "BillAmount":
                return "Importe";

            case "BillCode":
                return "BillCode";

            case "BranchName":
                return "BranchName";

            case "Employee":
                return currentReport==="RESUMEN PROMOTOR SELECCIONADO"?"Promotor": "Referido";

            case "Amount":
                return currentReport==="RESUMEN PROMOTOR SELECCIONADO"?"Nivel 1": "Importe";

            case "SumChild2":
                return currentReport==="RESUMEN PROMOTOR SELECCIONADO"?"Nivel 2": "SumChild2";

            case "Subtotal":
                return "Billetera";
            case "Parent":
                return "Referido de";
            case "Level":
                return "Nivel";
            case "CP":
                return currentReport == "BILLETERA POR NIVEL" ? "Total" : "Compras Personales";

            case "AccountId":
                return "AccountId";

            case "AccountDetailId":
                return "AccountDetailId";

            case "AccountDetailType":
                return "AccountDetailType";

            case "AccountDetailDate":
                return "Fecha";
            case "AccountDetailAmmount":
                return "AccountDetailAmmount";
            case "AccountDetailTask":
                return "AccountDetailTask";
            case "AccountDetailNetAmount":
                return "AccountDetailNetAmount";

            case "CreatedBy":
                return "CreatedBy";

            case "AccountDetailDescription":
                return "AccountDetailDescription";
            case "Deposit":
                return "Abono";
            case "Withdrawal":
                return "Retiro";
            case "Balance":
                return "Saldo";
            case "PCN":
                return "PCN";
            case "Wallet":
                return "Billetera";

        }
        return "";


    }
    useEffect(() => {
        // console.log("setting new report")
        //setReportSelected(true);
    }, [currentReport]);
    useEffect(() => {
        addLevels()
    }, [dataRow]);
    

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setCurrentPagination(+(event.target.value as string));
    };



    const handleChangePage = (event: unknown, newPage: number) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentPagination(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };


    function getTotal() {

        if (currentReport == "RESUMEN CLIENTE REFERIDOS") {
            
            let aux: number = 0;
            for (let i = 0; i < dataRow.length; i++) {

                aux += dataRow[i].Amount;
            }
            return aux;

        }else if(currentReport == "RESUMEN PROMOTOR SELECCIONADO"){

            let aux: number = 0;
            for (let i = 0; i < dataRow.length; i++) {

                aux += dataRow[i].Amount;
            }
            return aux;
            
        } else {

            let aux: number = 0;
            for (let i = 0; i < dataRow.length; i++) {

                aux += dataRow[i].CP;
            }
            return aux;

        }


    }

    function getTotal3() {

        if (currentReport == "COMPRAS PERSONALES") {
            
            let aux: number = 0;
            for (let i = 0; i < dataRow.length; i++) {

                aux += dataRow[i].BillAmount;
            }
            return aux;

        }

return 0;
    }
    function getTotal2() {
        if (currentReport == "RESUMEN CLIENTE REFERIDOS") {
            let aux: number = 0;
            for (let i = 0; i < dataRow.length; i++) {

                aux += dataRow[i].Subtotal;
            }
            return aux;

        } else if(currentReport == "RESUMEN PROMOTOR SELECCIONADO"){

            let aux: number = 0;
            for (let i = 0; i < dataRow.length; i++) {

                aux += dataRow[i].SumChild2;
            }
            return aux;
        }else {
            let aux: number = 0;
            for (let i = 0; i < dataRow.length; i++) {

                aux += dataRow[i].Wallet;
            }
            return aux;
        }
    }
    function getSubTotal() {
        if (currentReport == "RESUMEN CLIENTE REFERIDOS") {
            let aux: number = 0;
            let rowAux: Array<any> = dataRow.slice(currentPage * currentPagination, currentPage * currentPagination + currentPagination);
            for (let i = 0; i < rowAux.length; i++) {

                aux += rowAux[i].Amount;
            }
            return aux;

        } else if(currentReport == "RESUMEN PROMOTOR SELECCIONADO"){

            let aux: number = 0;
            let rowAux: Array<any> = dataRow.slice(currentPage * currentPagination, currentPage * currentPagination + currentPagination);
            for (let i = 0; i < rowAux.length; i++) {

                aux += rowAux[i].Amount;
            }
            return aux;
        }
        
        
        
        
        else {
            let aux: number = 0;
            let rowAux: Array<any> = dataRow.slice(currentPage * currentPagination, currentPage * currentPagination + currentPagination);
            for (let i = 0; i < rowAux.length; i++) {

                aux += rowAux[i].CP;
            }
            return aux;
        }
    }


    function getSubTotal3() {
        if (currentReport == "COMPRAS PERSONALES") {
            let aux: number = 0;
            let rowAux: Array<any> = dataRow.slice(currentPage * currentPagination, currentPage * currentPagination + currentPagination);
            for (let i = 0; i < rowAux.length; i++) {

                aux += rowAux[i].BillAmount;
            }
            return aux;

        } 
        return 0;
    }


    function getSubTotal2() {
        if (currentReport == "RESUMEN CLIENTE REFERIDOS") {
            let aux: number = 0;
            let rowAux: Array<any> = dataRow.slice(currentPage * currentPagination, currentPage * currentPagination + currentPagination);
            for (let i = 0; i < rowAux.length; i++) {

                aux += rowAux[i].Subtotal;
            }
            return aux;

        }else if(currentReport == "RESUMEN PROMOTOR SELECCIONADO"){
            let aux: number = 0;
            let rowAux: Array<any> = dataRow.slice(currentPage * currentPagination, currentPage * currentPagination + currentPagination);
            for (let i = 0; i < rowAux.length; i++) {

                aux += rowAux[i].SumChild2;
            }
            return aux;
            
        } else {
            let aux: number = 0;
            let rowAux: Array<any> = dataRow.slice(currentPage * currentPagination, currentPage * currentPagination + currentPagination);
            for (let i = 0; i < rowAux.length; i++) {

                aux += rowAux[i].Wallet;
            }
            return aux;
        }
        
    }

 



    function addLevels(){

        if(dataRow && dataRow.length){
let aux:number =0;
        let arrayWithLevels:Array<any>=[];
        let counterLevel:number=dataRow[0].Level;
        for (let i = 0; i < dataRow.length; i++) {

            arrayWithLevels.push( dataRow[i])

            aux += dataRow[i].CP;

            if(dataRow[i+1]){


                if(dataRow[i].Level==dataRow[i+1].Level){


                }else{

                    arrayWithLevels.push({total:aux})
                    aux=0;
                    }



            }else{

                arrayWithLevels.push({total:aux})
            }


        }
        // console.log("arrayWithLevels",arrayWithLevels)
        setDataRowsToShowPromotoresReferidos(arrayWithLevels);
        }
        

    }



    function round(num:any, decimales = 2) {
        const base = 10**decimales;
        const precioxBase = num*base;
    
        const rPrecioxBase = Math.round(precioxBase);
    
        const precioFinal = rPrecioxBase/base;
        return precioFinal;   
    }

    return (
    <div>
      {!loading?  <Grid item xs={12} >

            <div style={{ fontSize: "3.5rem", textAlign: "center", marginBottom: "15px" }}>

                {currentReport}

            </div>

            {dataRow && dataRow.length && currentReport ? <div>

                <TableContainer component={Paper}  style={{ }}>
                    <Table className={classes.table} aria-label="simple table" style={{tableLayout: "auto" }} >
                        <TableHead >
                            <TableRow style={{ backgroundColor: "#228B22" }}>

                                {currentProps.map((row, index) => (

                                    <TableCell  component="th" scope="row" align="left" style={{  color: "white" ,fontSize: "1.5rem" }} >
                                        {renamerColumn(row)}
                                    </TableCell>
                                ))}

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {hasData && currentReport!=="RESUMEN PROMOTORES REFERIDOS" && dataRow.slice(currentPage * currentPagination, currentPage * currentPagination + currentPagination).map((row, index) => (
                               <TableRow key={index}>
                                    {currentProps.map((row2, index2) => (
                                        
                                        <TableCell component="th" scope="row" align="left" style={{ fontSize: "1.2rem" }} >
                                            {getByProperty(row, row2)}
                                        </TableCell>

                                    ))}

                                </TableRow>  
                            ))}



                            {currentReport==="RESUMEN PROMOTORES REFERIDOS" &&  dataRowsToShowPromotoresReferidos.slice(currentPage * currentPagination, currentPage * currentPagination + currentPagination).map((row, index) => {
                                if(row.total){
                                   return( <TableRow key={index} style={{ backgroundColor: "#228B22" }}>
                                   
                                   <TableCell component="th" scope="row" align="left"  style={{  color: "white",fontSize: "1.2rem" }} >
                                   Total
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="left"   style={{  color: "white",fontSize: "1.2rem" }} >
                                           
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="left"   style={{ color: "white" ,fontSize: "1.2rem" }} >
                                           
                                        </TableCell>
                                        
                                        <TableCell component="th" scope="row" align="left"   style={{ color: "white" ,fontSize: "1.2rem"}} >
                                            {hasData?round(row.total):0}
                                         </TableCell>
                                    

                                </TableRow> )

                                }else{
                                    return(  <TableRow key={index}>
                                    {currentProps.map((row2, index2) => (
                                        
                                        <TableCell component="th" scope="row" align="left" style={{fontSize: "1.2rem"  }} >
                                            {getByProperty(row, row2)}
                                        </TableCell>

                                    ))}

                                </TableRow> 
                                    )
                                }

                             
                        })}



                            {hasTotal && !hasTwo ? <TableRow style={{ backgroundColor: "#228B22" }} >

                                <TableCell colSpan={1} component="th" scope="row" align="left" style={{  color: "white",fontSize: "1.2rem" }} >
                                    Sub Total-
                                        </TableCell>



                                <TableCell colSpan={1} component="th" scope="row" align="left" style={{ color: "white",fontSize: "1.2rem" }} >
                                    {hasData && currentReport=="COMPRAS PERSONALES"?round(getSubTotal3()):subTotal }
                                </TableCell>




                            </TableRow> : <></>}

                            {hasTotal && !hasTwo ? <TableRow style={{ backgroundColor: "#228B22" }} >

                                <TableCell colSpan={1} component="th" scope="row" align="left" style={{  color: "white",fontSize: "1.2rem" }} >
                                    Total-
                                </TableCell>



                                <TableCell colSpan={1} component="th" scope="row" align="left" style={{  color: "white",fontSize: "1.2rem" }} >
                                    {hasData  && currentReport=="COMPRAS PERSONALES" ?round(getTotal3()):total}
                                </TableCell>


                            </TableRow> : <></>}


                            {hasTotal && hasTwo ? <TableRow style={{ backgroundColor: "#228B22" }} >

                                <TableCell colSpan={1} component="th" scope="row" align="left" style={{  color: "white",fontSize: "1.2rem" }} >
                                    Sub Total
                                </TableCell>



                                <TableCell colSpan={1} component="th" scope="row" align="left" style={{  color: "white" ,fontSize: "1.2rem"}} >
                                    {hasData?round(getSubTotal()):0}
                                </TableCell>

                                <TableCell colSpan={1} component="th" scope="row" align="left" style={{  color: "white",fontSize: "1.2rem" }} >
                                    {hasData?round(getSubTotal2()):0}
                                </TableCell>



                            </TableRow> : <></>}

                            {hasTotal && hasTwo ? <TableRow style={{ backgroundColor: "#228B22" }} >

                                <TableCell colSpan={1} component="th" scope="row" align="left" style={{ color: "white" ,fontSize: "1.2rem"}} >
                                    Total
                                </TableCell>



                                <TableCell colSpan={1} component="th" scope="row" align="left" style={{ color: "white" ,fontSize: "1.2rem"}} >
                                    {hasData?round(getTotal()):0}
                                </TableCell>

                                <TableCell colSpan={1} component="th" scope="row" align="left" style={{  color: "white",fontSize: "1.2rem" }} >
                                    {hasData?round(getTotal2()):0}
                                </TableCell>

                            </TableRow> : <></>}

                        </TableBody>


                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[10, 50,100]}
                    component="div"
                    count={dataRow.length}
                    rowsPerPage={currentPagination}
                    page={currentPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <div className={classes.root}>


                </div>
            </div> : <></>}
        </Grid>:<CircularProgress />
      
}
    </div>
    );

}
export default FormComponent;