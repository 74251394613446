import React, { useEffect, useState } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MuiAlert from '@material-ui/lab/Alert';

import { Cart, OrderDetailItem, OrderDetail, Order, ClientModel, EmployeeModel, UserModel } from '../Models/Models';
import { cloneDeep } from 'lodash';
// import { fetchViewList8, fetchViewListChange, SendOrder2 } from '../Services/Services';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import { fetchViewListEmployeeChilds, SendOrder2 } from '../Services/Services';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import '../Styles/tableStyles.css';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '30%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        }
    }, labelRoot: {
        fontSize: 15,
        color: "black",
        "&$labelFocused": {
            color: "purple"
        }, marginLeft: "15px"
    }, paper: {
        position: 'absolute',
        width: 450,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    paper2: {
        position: 'absolute',
        width: 300,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },
}));
interface CheckoutProps {
    CurrentCart: Cart;
    SetCurrentCart: Function;
}
function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,

    };
}
function rand() {
    return 0;
}
const CheckoutPage: React.FC<CheckoutProps> = ({ CurrentCart, SetCurrentCart }) => {
    let DrawCart: Cart = { UserName: "", UserNit: "", ProductCartDetail: [] }

    const classes = useStyles();
    var oldCart: Cart = { UserName: "", UserNit: "", ProductCartDetail: [] };
    let [currentCart, setCurrentCart] = useState(DrawCart);
    let [counter, setCounter] = useState<number>(0);
    let [currentOrder, setCurrentOrder] = useState<Order>();
    let [cellphone, setCellphone] = useState('');
    const [valueUserAccount, setValueUserAccount] = React.useState('Registrar cuenta');
    const [valuePaymentMethod, setValuePaymentMethod] = React.useState('Transferencia bancaria');
    let [currentUserLogged, setCurrentUserLogged] = useState<any>();
    const [city, setCity] = useState('Cochabamba');
    const [fullName, setFullName] = useState('');
    const [address, setAddress] = useState('');
    const [billName, setBillName] = useState('');
    const [billNit, setBillNit] = useState('');
    const [observaciones, setObservaciones] = useState('');
    const [orderNumber, setOrderNumber] = React.useState(0);
    const [openCheckBox1, setOpenCheckBox1] = React.useState(false);
    const [openCheckBoxError, setOpenCheckBoxError] = React.useState(false);
    const [openCheckBoxData, setOpenCheckBoxData] = React.useState(false);
    const [openCheckBoxUser, setOpenCheckBoxUser] = React.useState(false);

    const [parentNamee, setParentName] = useState<string>("");
    const [modalStyle] = React.useState(getModalStyle);
    const [openModal, setOpenModal] = React.useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
       setView('inicio')
        setOpenModal(false);
    };

    const [EmployeeList, setEmployeeList] = React.useState<EmployeeModel>();

    const handleChangePaymentMethod = (event: any) => {
        setValuePaymentMethod(event.target.value);
    };
    const handleChangeUserAccount = (event: any) => {
        setValueUserAccount(event.target.value);
    };
    const [checked, setChecked] = React.useState(true);

    const handleChange = (event: any) => {
        setChecked(event.target.checked);
    };
    const CryptoJS = require('crypto-js');
    //let { Id } = useParams();
    useEffect(() => {


        sendOrder(CurrentCart);
    }, [CurrentCart]);

    const handleClick1 = () => {
        setOpenCheckBox1(true);
    };

    const handleClose1 = (event: any, reason: any) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenCheckBox1(false);
    };

    function setView(value: any) {

     
        sessionStorage.setItem('currentView', value);
    
      }
    const handleClickError = () => {
        setOpenCheckBoxError(true);
    };

    const handleCloseError = (event: any, reason: any) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenCheckBoxError(false);
    };

    const handleClickData = () => {
        setOpenCheckBoxData(true);
    };

    const handleClickUser = () => {
        setOpenCheckBoxUser(true);
    };

    const handleCloseData = (event: any, reason: any) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenCheckBoxData(false);
    };
    const handleCloseUser = (event: any, reason: any) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenCheckBoxUser(false);
    };



    useEffect(() => {

        var guardado = sessionStorage.getItem('keyName');

        if (guardado != null) {
            oldCart = JSON.parse(guardado);
            DrawCart = cloneDeep(oldCart)
            // Current/Cart=(oldCart);

        } else {
            DrawCart = cloneDeep(CurrentCart);
        }
    });
    useEffect(() => {


        setCurrentCart(DrawCart);
    }, []);
    useEffect(() => {

        SetCurrentCart(DrawCart);
        //CurrentCart = oldCart;
        setCurrentCart(DrawCart);

    }, [counter]);

    function large(){
        let width = window.innerWidth;
        return width>768;

    }
    useEffect(() => {

        let cookieUser: any = sessionStorage.getItem("user");
        let currentUser: any = JSON.parse(cookieUser);

        if (currentUser !== undefined && currentUser !== null) {
            setCurrentUserLogged(currentUser);

            setFullName(currentUser.ClientFirstName + " " + currentUser.ClientLastName1 + " " + currentUser.ClientLastName2)

            console.log("currentUser", currentUser)
            setAddress(currentUser.ClientAddress ? currentUser.ClientAddress.toString() : "");
            setBillName(currentUser.ClientName ? currentUser.ClientName.toString() : "");
            setBillNit(currentUser.ClientNIT ? currentUser.ClientNIT.toString() : "");


     
            // setAddress(currentUser?.ClientAddress ? currentUser?.ClientAddress.toString() : "");
            // setBillName(currentUser?.ClientName ? currentUser?.ClientName.toString() : "");
            // setBillNit(currentUser?.ClientNIT ? currentUser?.ClientNIT.toString() : "");
            setCellphone(currentUser.ClientCelularPhone ? currentUser.ClientCelularPhone.toString() : "")
        }

    }, []);

    function isUserlogged(): boolean {


        let currentUser: any = sessionStorage.getItem("user");


        if (currentUser == null) {

            return false
        }

        else { return true; }
    }

    function GetCurrentImage(productCart: OrderDetailItem) {

        let currentImage: string = productCart.ProductImage.toString();
        return currentImage;
        // var http = new XMLHttpRequest();
        // try {

        //     http.open('HEAD', currentImage, false);
        //     http.send();

        //     if (http.status === 404) {

        //         return "/no-image.png";
        //     }
        //     return currentImage;
        // } catch (e) {

        //     return "/no-image.png";
        // }
    }


    useEffect(() => {

        getDataFromUser()
    }, []);

    async function getDataFromUser() {
        var guardado = sessionStorage.getItem('user');
        if (guardado == null || guardado == undefined) {

        } else {
            let currentUser: UserModel = JSON.parse(guardado ? guardado : "");
            setCurrentUserLogged(currentUser);
            //setParentName(currentUser.UserName.toString());
            let currentEmployeee: EmployeeModel = await fetchViewListEmployeeChilds(currentUser.EmployeeId.toString());
            setEmployeeList(currentEmployeee);

        }

    }

    function DropCartItem(productCart: OrderDetailItem) {

        setCounter(counter + 1)

        let oldCart: Cart = CurrentCart as Cart;
        let oldCartDetail: OrderDetailItem[] = [];

        if (CurrentCart && CurrentCart.ProductCartDetail) {
            for (let i = 0; i < CurrentCart.ProductCartDetail.length; i++) {

                if (CurrentCart.ProductCartDetail[i].ProductId !== productCart.ProductId) {
                    oldCartDetail.push(CurrentCart.ProductCartDetail[i]);
                }
            }
            oldCart.ProductCartDetail = cloneDeep(oldCartDetail);

            SetCurrentCart(oldCart);
            //CurrentCart = oldCart;
            setCurrentCart(oldCart);
            sessionStorage.setItem("keyName", JSON.stringify(oldCart));
            DrawCart = cloneDeep(oldCart);
        }


    }
    async function SendClientorder() {

        let parentNameAux=  sessionStorage.getItem('parentName')
  

       console.log(parentNamee)


            var d = new Date();

            let currentUser: any = sessionStorage.getItem("user");
            let currentUserModel: ClientModel;

         

            if (currentUser !== null && currentUser !== undefined) {
                if (currentOrder != undefined) {
                    let actualOrder: Order = {
                        address: "",
                        billName: "",
                        billNit: "",
                        branchId: 1,
                        clientId: parentNameAux?+parentNameAux:0,
                        // clientId: parentNameAux?.toString()?+parentNameAux.toString():0,
                        code: "PEDIDO",
                        items: currentOrder.items,
                        latitude: 0,
                        longitude: 0,
                        observation: observaciones,
                        scheduledDate: d,
                        state: "",
                        tableId: 0,
                        type: "",
                        userId: 1


                    }
                    console.log("oirder setted")

                    console.log("oirder setted", actualOrder)
                    let orderNum: any = await SendOrder2(actualOrder);
                    setOrderNumber(orderNum);
                    console.log("orderNum", orderNum)
                    setCurrentOrder(actualOrder);
                    handleOpenModal();
                    currentUserModel = JSON.parse(currentUser);
                    dropCart();
                    // sendOrderDetail(actualOrder);
                    // handleClick1();

                }



            } else {
                // handleClickUser();
            }



           
        
    }

    function sendOrder(orderCart: Cart) {
        let currentUser: any = sessionStorage.getItem("user");
        let currentUserModel: ClientModel;
        if (currentUser !== null && currentUser !== undefined) {
            currentUserModel = JSON.parse(currentUser);
        }
        let order: Order = {
            tableId: 0,
            clientId: 2,
            branchId: 1,
            userId: 1,
            scheduledDate: null,
            observation: "",
            code: "PEDIDO",
            type: "PEDIDO",
            latitude: 0,
            longitude: 0,
            items: [],
            state: "ENVIADO",
            billName: "",
            billNit: "",
            address: ""
        }
        function addToOrder(cartElement: OrderDetailItem, actualOrder: Order) {
            let newOrderToAdd: OrderDetail = {
                branchId: actualOrder.branchId,
                productId: cartElement.ProductId,
                name: cartElement.ProductName,
                price: cartElement.ProductSalePrice,
                quantity: cartElement.ProductQuantity,
                imageUrl: cartElement.ProductImage,
                observation: ""
            }
            actualOrder.items.push(newOrderToAdd);
        }
        if (orderCart && orderCart.ProductCartDetail) {
            if (orderCart.ProductCartDetail.length > 0) {
                orderCart.ProductCartDetail.forEach(orderElement => addToOrder(orderElement, order));
            }
            setCurrentOrder(order);
        }


    }
    function getData(){
        var currentTime = new Date();
        return currentTime.toString();
    }
    function encrypt(text: string) {
        return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
    };
    // const sendOrderDetail = async (currentOrder1:Order) => {
    //     if (currentOrder != undefined){
    //         let orderNum : any = await SendOrder2(currentOrder1);
    //         console.log("->",orderNum);
    //         dropCart();


    //         let client: ClientModel = {
    //             id: currentUserLogged.ClientId,
    //             firstName: currentUserLogged.ClientFirstName,
    //             lastName1: currentUserLogged.ClientLastName1,
    //             lastName2: currentUserLogged.ClientLastName2,
    //             email: currentUserLogged.ClientEmail,
    //             address: currentUserLogged.ClientAddress,
    //             latitude: 0,
    //             longitude: 0,
    //             mobile: currentUserLogged.ClientCelularPhone,
    //             name: currentUserLogged.ClientFirstName,
    //             password: currentUserLogged.ClientPassword,
    //             phone: currentUserLogged.ClientPhone,
    //             ciudad: currentUserLogged.ClientNationality,
    //             billName: billName,
    //             billNit: billNit,
    //             documentNumber: currentUserLogged.ClientDocumentNumber,
    //             localizationId: 1,
    //             observaciones: observaciones,
    //             parentId: 0,
    //             tipo: ""
    //         }
    //         await fetchViewListChange(client);

    //         let result : string = await fetchViewListChange(client);
    //         console.log("clientttttttttttt",result)



    //           let userResult : any = await fetchViewList8(client.email,client.password?client.password:"")
    //       if(userResult!==null && userResult!= undefined){
    //             console.log("userResult",userResult)
    //          let user :ClientModel = userResult.client;



    //           sessionStorage.setItem("user", JSON.stringify(user));    

    //       }






    //     }

    // }
    // async function  sendOrderDetail()  {
    //     if (currentOrder != undefined)
    //     let orderNum : any = await SendOrder2(currentOrder);
    //         // console.log("-->",SendOrder2(currentOrder));
    // }

    function dropCart() {
        let cart: Cart = { UserName: "Nick", UserNit: "11010110", ProductCartDetail: [] }
        sessionStorage.setItem("keyName", JSON.stringify(cart));
        SetCurrentCart(cart);
    }

    function Alert(props: any) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    function iniciarSesion() {
        // history.push("/login");
        sessionStorage.setItem('fromCheckout', 'true');
    }

    function crearCuenta() {
        // history.push("/register");
        sessionStorage.setItem('fromCheckout', 'true');
    }



    return (
        <div>
            <div id="container" style={{ marginTop: "-20px" }}>
                <div className="container">


                    <div className="row">

                        <div id="content" className="col-sm-12">
                            <h1 className="title">Confirmar Datos</h1>
                            <div className="row">

                                <div className="col-sm-12">
                                    <div className="row">

                                        <div className="col-sm-12">
                                            <div className="panel panel-default">
                                                <div className="panel-heading" style={{backgroundColor:"#CCCCCC"}}>
                                                    <h4 className="panel-title"><i className="fa fa-shopping-cart"></i> Carrito de Compras</h4>
                                                </div>
                                                <div className="panel-body" style={{backgroundColor:"#CCCCCC"}} >
                                                    <div >

                                                        <table role="table" style={{width:"100%"}} >
                                                            <thead role="rowgroup">
                                                            {
                                                                    large()?  <tr role="row" className="TableHidden">
                                                                    <th role="columnheader" className="text-left">Imagen</th>
                                                                    <th role="columnheader" className="text-left">Nombre del Producto</th>
                                                                    <th role="columnheader" className="text-left">Cantidad</th>
                                                                    <th role="columnheader" className="text-left">Precio Unitario</th>
                                                                    <th role="columnheader" className="text-left">SubTotal</th>
                                                                   
                                                                </tr>:null
                                                                }
                                                               
                                                            </thead>
                                                            <tbody role="rowgroup">
                                                              
                                                               
                                                                    {
                                                                    CurrentCart.ProductCartDetail && CurrentCart.ProductCartDetail.length > 0 ?
                                                                        CurrentCart.ProductCartDetail.map((item, index) => {
                                                                            return <tr role="row">
                                                                                <td role="cell" className="text-left"><a><img style={{ "height": "100px" }} src={GetCurrentImage(item)} alt="" /></a></td>
                                                                                <td  role="cell" className="text-left"><a >{item.ProductName}</a></td>
                                                                                <td role="cell" className="text-left"><div className="input-group btn-block" style={{ "maxWidth": " 200px" }}>
                                                                                    <input style={{ "width": "40px" }} type="text" name="quantity" value={+item.ProductQuantity} size={1} className="form-control" />
                                                                                    <span className="input-group-btn">

                                                                                        <button style={{ "marginTop": "0px" }} type="button" data-toggle="tooltip" title="Remove" className="btn btn-danger" onClick={() => { DropCartItem(item) }} ><i className="fa fa-times-circle"></i></button>
                                                                                    </span></div></td>
                                                                                <td role="cell" className="text-right">{item.ProductSalePrice} Bs.</td>
                                                                                <td role="cell" className="text-right">{+item.ProductSalePrice * +item.ProductQuantity} Bs.</td>
                                                                            </tr>
                                                                        }) : <></>
                                                                }





                                                                {/* </tr> */}
                                                            
                                                              
                                                            </tbody>
                                                           
                                                              
                                                              
                                                                
                                                          
                                                        </table>
                                                        <div className="text-right" ><strong>Total:</strong></div>
                                                                  <div className="text-right">{currentOrder?.items.reduce((a, b) => +a + +(+b["price"] * +b["quantity"] || 0), 0)}Bs.</div>
                                                              


                                                        {/* <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <td className="text-center">Imagen</td>
                                                                    <td className="text-left">Nombre del Producto</td>
                                                                    <td className="text-left">Cantidad</td>
                                                                    <td className="text-right">Precio Unitario</td>
                                                                    <td className="text-right">Total</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    CurrentCart.ProductCartDetail && CurrentCart.ProductCartDetail.length > 0 ?
                                                                        CurrentCart.ProductCartDetail.map((item, index) => {
                                                                            return <tr>
                                                                                <td className="text-center"><a><img style={{ "height": "100px" }} src={GetCurrentImage(item)} alt="" /></a></td>
                                                                                <td className="text-left"><a >{item.ProductName}</a></td>
                                                                                <td className="text-left"><div className="input-group btn-block" style={{ "maxWidth": " 200px" }}>
                                                                                    <input style={{ "width": "40px" }} type="text" name="quantity" value={+item.ProductQuantity} size={1} className="form-control" />
                                                                                    <span className="input-group-btn">

                                                                                        <button style={{ "marginTop": "0px" }} type="button" data-toggle="tooltip" title="Remove" className="btn btn-danger" onClick={() => { DropCartItem(item) }} ><i className="fa fa-times-circle"></i></button>
                                                                                    </span></div></td>
                                                                                <td className="text-right">{item.ProductSalePrice} Bs.</td>
                                                                                <td className="text-right">{+item.ProductSalePrice * +item.ProductQuantity} Bs.</td>
                                                                            </tr>
                                                                        }) : <></>
                                                                }
                                                            </tbody>
                                                            <tfoot>
                                                              
                                                                <tr>
                                                                    <td className="text-right" colSpan={4}><strong>Total:</strong></td>
                                                                    <td className="text-right">{currentOrder?.items.reduce((a, b) => +a + +(+b["price"] * +b["quantity"] || 0), 0)}Bs.</td>
                                                                </tr>
                                                            </tfoot>
                                                        </table> */}

                                                    </div>
{/* 
                                                    <FormControl className="form-control">
                                                        <InputLabel className={classes.labelRoot} id="demo-simple-select-label">Referido a:</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={parentNamee}
                                                            onChange={e => setParentName((e.target as HTMLInputElement).value)}
                                                            disableUnderline
                                                            style={{ marginLeft: "20px", fontSize: "15px" }}

                                                        >
                                                            <MenuItem value={currentUserLogged?.EmployeeId.toString()}>{currentUserLogged?.UserName}</MenuItem>
                                                            {EmployeeList && EmployeeList.Children.map(m => {
                                                                return <MenuItem value={m.Id.toString()}>{m.CompleteName}</MenuItem>



                                                            })}



                                                        </Select>
                                                    </FormControl> */}
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="col-sm-12">
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <h4 className="panel-title"><i className="fa fa-credit-card"></i> Método de pago</h4>
                                                </div>
                                                <div className="panel-body">

                                                    <FormControl component="fieldset">
                                                        <p>Por favor seleccionar un método de pago.</p>
                                                        <RadioGroup aria-label="gender" name="gender1" value={valuePaymentMethod} onChange={handleChangePaymentMethod}>
                                                        
                                                            <FormControlLabel value="Transferencia bancaria" control={<Radio color="primary" />} label={<span style={{ fontSize: '16px' }}>Transferencia bancaria</span>} />
                                                            <FormControlLabel value="Click Pay" control={<Radio color="primary" />} label={<span style={{ fontSize: '16px' }}>Pagos por Click Pay</span>} />
                                                            <FormControlLabel value="Pago QR" control={<Radio color="primary" />} label={<span style={{ fontSize: '16px' }}>Pagos por QR</span>} />

                                                        </RadioGroup>
                                                    </FormControl>

                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-sm-12">
                                            <div className="panel panel-default">
                                                {/*<div className="panel-heading">
                                                    <label className="control-label" htmlFor="confirm_agree">
                                                        <Checkbox
                                                            style={{ width: "35px", height: "35px", marginTop: "-3px" }}
                                                            color="primary"
                                                            checked={checked}
                                                            onChange={handleChange}></Checkbox>
                                                
                                                        <span> He leído y estoy de acuerdo con los  <a className="agree" ><b>Términos &amp; Condiciones</b></a></span> </label>

                                    </div>*/}

                                                {/* - {parentName} - */}
                                                <div className="panel-body">

                                                    <div className="buttons">
                                                        <div className="pull-right">
                                                            <button className="btn btn-primary" id="button-confirm" onClick={() => { SendClientorder() }} > Realizar Compra</button>
                                                        </div>
                                                    </div>
                                                    <div className={classes.root} >
                                                        <Snackbar style={{ width: "50%" }} open={openCheckBox1} autoHideDuration={4000} onClose={handleClose1}>
                                                            <Alert style={{ width: "50%", fontSize: "14px" }} onClose={handleClose1} severity="success">
                                                                Orden registrada
                                                     </Alert>
                                                        </Snackbar>
                                                        <Snackbar style={{ width: "50%" }} open={openCheckBoxError} autoHideDuration={4000} onClose={handleCloseError}>
                                                            <Alert style={{ width: "50%", fontSize: "14px" }} onClose={handleCloseError} severity="error">
                                                                Por favor acepte los términos & condiciones
                                                     </Alert>
                                                        </Snackbar>
                                                        <Snackbar style={{ width: "50%" }} open={openCheckBoxData} autoHideDuration={4000} onClose={handleCloseData}>
                                                            <Alert style={{ width: "50%", fontSize: "14px" }} onClose={handleCloseData} severity="error">
                                                                Por favor seleccione un usuario referido
                                                     </Alert>
                                                        </Snackbar>
                                                        <Snackbar style={{ width: "50%" }} open={openCheckBoxUser} autoHideDuration={4000} onClose={handleCloseUser}>
                                                            <Alert style={{ width: "50%", fontSize: "14px" }} onClose={handleCloseUser} severity="error">
                                                                Por favor inicie sesión o se registre como nuevo usuario
                                                     </Alert>
                                                        </Snackbar></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                 
                </div>   <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={openModal}
                onClose={handleCloseModal}
            >
                <div style={modalStyle} className={classes.paper2}>
                    <h2>Resumen de compra</h2>
                    <p>
                        Id de orden: {orderNumber}
                    </p>
                    <p>
                        fecha: {getData()}
                    </p>
                    <Button onClick={()=>{setView('pedidos')}}> Ir a Pedidos</Button>
                </div>
            </Modal>
            </div>

        </div >

    );
}



export default CheckoutPage;
