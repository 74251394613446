
import { ClientModel, PeriodModel, AgentPromotorModel, ClientPromotorModel, ReportModel, UserModel, WalletModel, ReportWalletLevelModel, ReportModelToShow, ReportWalletLevelModelToShow, WalletModelToShow, ClientPromotorModelToShow, ClientPromotorModelToShow2 } from './Models/Models';


export function mapperToReportModel(genericArray: Array<any>): Array<ReportModelToShow> {
    let arrayResult: Array<ReportModelToShow> = [];

    for (let i = 0; i < genericArray.length; i++) {
        let date:string = genericArray[i].BillDate.split("T");
        arrayResult.push({ BillDate: date[0], BillAmount: genericArray[i].BillAmount, Source: genericArray[i].Source })

    }
    // if(arrayResult.length==0){
    //     arrayResult.push({ BillDate:"-", BillAmount: 0, Source: "-" })

    // }
    return arrayResult;

}
export function mapperToWalletLevel(genericArray: Array<any>): Array<ReportWalletLevelModelToShow> {
    let arrayResult: Array<ReportWalletLevelModelToShow> = [];

    for (let i = 0; i < genericArray.length; i++) {

        arrayResult.push({ Level: genericArray[i].Level, CP: genericArray[i].CP, Wallet: genericArray[i].Wallet })

    }
    // if(arrayResult.length==0){
    //     arrayResult.push({ Level: 0, CP: 0, Wallet:  0})

    // }
    return arrayResult;

}
export function mapperToWalletModel(genericArray :Array<any> ):Array<WalletModelToShow>{
    let arrayResult : Array<WalletModelToShow>=[];
    
    for(let i =0 ; i< genericArray.length;i++){
    let date:string = genericArray[i].AccountDetailDate.split("T");
    arrayResult.push({AccountDetailDate:date[0], Deposit :    genericArray[i].Deposit,Withdrawal:    genericArray[i].Withdrawal,Balance:    genericArray[i].Balance})
    
    }
    console.log("arrayResult",arrayResult)
    return arrayResult;
    
    }

    export function mapperToClientPromotor(genericArray: Array<any>): Array<ClientPromotorModelToShow> {
        let arrayResult: Array<ClientPromotorModelToShow> = [];
    
        for (let i = 0; i < genericArray.length; i++) {
    
            arrayResult.push({Employee:genericArray[i].Employee, Amount:genericArray[i].Amount,Subtotal:genericArray[i].Subtotal })
    
        }
        // if(arrayResult.length==0){
        //     arrayResult.push({ Amount:0 ,Employee:"-",Subtotal:0})
    
        // }
        return arrayResult;
    
    }
    export function mapperToClientPromotor2(genericArray: Array<any>): Array<ClientPromotorModelToShow2> {
        let arrayResult: Array<ClientPromotorModelToShow2> = [];
    
        for (let i = 0; i < genericArray.length; i++) {
    
            arrayResult.push({Employee:genericArray[i].Employee, Amount:genericArray[i].Amount,SumChild2:genericArray[i].SumChild2 })
    
        }
        // if(arrayResult.length==0){
        //     arrayResult.push({ Amount:0 ,Employee:"-",SumChild2:0})
    
        // }
        return arrayResult;
    
    }
    