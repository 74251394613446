import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import CardItem from './CardItem'
import { ProductType, Cart, UserModel } from '../Models/Models';
import { fetchViewListProducts } from '../Services/Services';
import AppNavBarCart from './AppNavBarCart';
import "../Styles/Header.css";

interface AppNavBarProps {
  CurrentCart: Cart;
  SetCurrentCart: Function;

}

const HeaderComponent: React.FC<AppNavBarProps> = ({ CurrentCart, SetCurrentCart }) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [isVisible2, setIsVisible2] = React.useState(false);
  const [cantidad, setCantidad] = React.useState(0);
  const [respon, setRespon] = React.useState(false);
  function setView(value: any) {

    // if (value == "reportes") {
    //   document.getElementById("slider").hidden = true;
    // } if (value == "inicio") {
    //   document.getElementById("slider").hidden = false;
    // }
    sessionStorage.setItem('currentView', value);

  }


  function closeSesion() {
    sessionStorage.removeItem('user');

    window.location.reload();
    setView('');
    dropCart();
  };

  function GetCartStatus() {
    let counter = 0;
    for (let i = 0; i < CurrentCart.ProductCartDetail.length; i++) {
      counter += +CurrentCart.ProductCartDetail[i].ProductQuantity;
    }
    return counter;
  }
  function ShowChart() {

    // use the value
    // remove the value when you are done so that this code doesn't run every time
    if (isVisible2 == false) { setIsVisible2(true) } else { setIsVisible2(false) }


    // sessionStorage.setItem( 'key', modalValue );


  }

  function GetCartTotal() {
    let total = 0;
    for (let i = 0; i < CurrentCart.ProductCartDetail.length; i++) {

      total += +CurrentCart.ProductCartDetail[i].ProductQuantity * +CurrentCart.ProductCartDetail[i].ProductSalePrice;
    }
    return total;
  }
  function goCheckout() {
    ShowChart();

    let cookieUser: any = sessionStorage.getItem("user");
    let currentUserlogged: UserModel = JSON.parse(cookieUser);

    if (currentUserlogged) {
      sessionStorage.setItem('currentView', 'check')
      
    }else{
      myFunction3('hover')

    }
  }

  
  function dropCart() {
    let cart: Cart = { UserName: "Nick", UserNit: "11010110", ProductCartDetail: [] }
    sessionStorage.setItem("keyName", JSON.stringify(cart));
    SetCurrentCart(cart);
  }

  function myFunction2(value: any) {
    sessionStorage.setItem("cart", value)
  }
  function myFunction3(value: any) {

    myFunction2(value)
    var aux = sessionStorage.getItem("cart2");
    if (aux == undefined || aux == null || aux == "unhover") {
      sessionStorage.setItem("cart2", value)

    } else if (aux == "hover") { sessionStorage.setItem("cart2", "unhover") }
    else { { sessionStorage.setItem("cart2", "unhover") } }

  }

  function isUserlog() {


    var guardado = sessionStorage.getItem('user');
    if (guardado == null || guardado == undefined) {

    } else {
      let currentUser: UserModel = JSON.parse(guardado ? guardado : "");

      if (currentUser !== null && currentUser !== undefined) {
        return true;
      }

    }
    return false;
  }

  const { height, width } = useWindowDimensions();

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  useEffect(() => {

    if (width < 767) {
      setRespon(true);
    } else { setRespon(false); }
  }, [width]);

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }


  return (
    <header style={{ backgroundColor: "#DCDCDC" }} >
      <div className="container">
        <div className="row">
          <div className="col-md-12">

            <nav className="navbar navbar-default">
              <div className="container-fluid">

                <div className="navbar-header">
                  <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                    data-target="#bs-example-navbar-collapse-1">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                  <a className="navbar-brand" >
                    <img src="https://montecristobolivia.com/wp-content/uploads/2019/04/brand.png" alt="Logo" style={{ width: "240px", marginTop: "10px" }} onClick={() => { sessionStorage.setItem('currentView', "inicio") }} />
                  </a>
                </div>

                <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                  <ul className="nav navbar-nav navbar-right" >
                    {/* style={{color:"black" , backgroundColor:"black",borderRadius:"50%"}} */}
                    <li className={"div1"} ><a onClick={() => { setView('inicio') }} >Inicio</a></li>

                    {!isUserlog() ? <li className={"div2"}><a onClick={() => { myFunction3('hover') }}  >Mi Cuenta</a></li> : <></>}
                    {isUserlog() ? <li className={"div3"} id="link-menu"><a onClick={() => { setView('reportes') }} >Reportes</a></li> : <></>}
                    {isUserlog() ? <li className={"div4"} id="pedidos"><a onClick={() => { setView('pedidos') }}  >Pedidos</a></li> : <></>}
                    {isUserlog() ? <li className={"div5"} id="reportes"><a onClick={() => { setView('registro') }}  >Registro</a></li> : <></>}
                    <li id="reportes" className={"div6"}><a onClick={() => { setView('productos') }} >Productos</a></li>
                    <li id="cart" className={"div1"} ><a onClick={() => { respon ? setView('check') : ShowChart() }}  >Carrito</a> </li>
                    {isUserlog() ? <li id="sesion" className={"div2"}><a onClick={() => { closeSesion() }}  >Cerrar Sesión</a></li> : <></>}

                    <div className="dropdown" style={{ display: "inline-block" }}   >
                      {isVisible2 ? <div className="dropdown-content" style={{ marginBottom: "20px", borderRadius: "12px", marginRight: "50%" }} >
                        <div className="row total-header-section">
                        <div style = {{float:"right"}}><CloseIcon onClick={() => { respon ? setView('check') : ShowChart() }}></CloseIcon></div><br></br>
                          <div className="col-lg-6 col-sm-6 col-6" style={{ "float": "left" }}>
                            <i className="fa fa-shopping-cart" style={{ "float": "left" }}></i> <span className="badge badge-pill badge-danger" style={{ "float": "left", marginLeft: "10px" }}>{GetCartStatus()} </span>
                          </div>
                          <div className="col-lg-6 col-sm-6 col-6 total-section text-right">
                            <p style={{ color: "black" }} >Total: <span style={{ color: "black" }} >Bs {GetCartTotal()}</span>  <i className="fa fa-trash cart-icon2 " aria-hidden="true" style={{ "float": "right", marginLeft: "10px", marginTop: "3px", display: "flex", alignItems: "center" }} onClick={() => { dropCart() }} ></i></p>                            
                          </div>                          
                        </div>
                        <div className="ex3">
                          <AppNavBarCart CurrentCart={CurrentCart} SetCurrentCart={SetCurrentCart}></AppNavBarCart>

                        </div>
                        <div className="row">
                          <div className="col-lg-12 col-sm-12 col-12 text-center checkout">
                            <button className="btn btn-primary btn-block" style={{ backgroundColor: "#598059" }} onClick={() => { goCheckout() }}>Confirmar Pedido</button>
                          </div>
                        </div>
                      </div> : <></>



                      }
                    </div>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>




  );
}



export default HeaderComponent;
