import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import '../Styles/AppNavBar.css';
import { Cart, OrderDetailItem } from '../Models/Models';
import { makeStyles } from '@material-ui/core/styles';


function Alert(props:any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));



interface AppNavBarCartProps {
  CurrentCart:Cart;
  SetCurrentCart:Function;
}


const AppNavBarCart: React.FC<AppNavBarCartProps> = ({CurrentCart,SetCurrentCart}) => {


  let DrawCart : Cart = {UserName:"",UserNit:"",ProductCartDetail:[]}
  var oldCart:Cart={UserName:"",UserNit:"",ProductCartDetail:[]};
  let [currentCart,setCurrentCart] = useState(DrawCart);
  let [counter,setCounter] = useState<number>(0);


  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event:any, reason:any) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };




  useEffect(() => {

      var guardado = sessionStorage.getItem('keyName');
      console.log("AppCurrent Cart Recuperado-->",guardado);
      if(guardado!=null){
        oldCart = JSON.parse(guardado);
        DrawCart = cloneDeep(oldCart)
      // Current/Cart=(oldCart);
      console.log("seteado");
      }else{
        DrawCart = cloneDeep(CurrentCart);
        
        console.log("Cart-->",CurrentCart);
        console.log("Drwa Cart-->",DrawCart);
      }


  } );

  useEffect(() => {

    var guardado = sessionStorage.getItem('keyName');
    console.log("callingthis mwthos--",guardado);
    if(guardado!=null){
      oldCart = JSON.parse(guardado);
      DrawCart = cloneDeep(oldCart)
      setCurrentCart(DrawCart);
      // Current/Cart=(oldCart);
    console.log("seteado");
    }else{
      DrawCart = cloneDeep(CurrentCart);
      
      console.log("Cart-->",CurrentCart);
      console.log("Drwa Cart-->",DrawCart);
    }


},[CurrentCart] );


  useEffect(() => {
   
    console.log("Cart-->",CurrentCart);
    console.log("Drwa Cart-->",DrawCart);
    setCurrentCart(DrawCart);
  } ,[]);



    useEffect(() => {
   
    console.log("counter",counter);

    SetCurrentCart(DrawCart);
 
    setCurrentCart(DrawCart);

  } ,[counter]);

  
  
  function GetCurrentImage(productCart:OrderDetailItem){

    let currentImage: string = productCart.ProductImage.toString();
    return currentImage;
    // var http  = new XMLHttpRequest();
    // try{
  
    //   http.open('HEAD', currentImage, false);
    //   http.send();
    
    //   if(http.status===404){
    
    //     return "/no-image.png";
    //   }
    //   return currentImage;
    // }catch( e){
  
    //   return "/no-image.png";
  
    // }
  }
  function DropCartItem(productCart:OrderDetailItem){

    setCounter(counter+1)
    console.log("dropde",productCart)
    let oldCart:Cart = CurrentCart as Cart;
    let oldCartDetail:OrderDetailItem[] = [];

    // console.log("oldCARRRRTTTT",oldCart)
    // console.log("droped item",productCart)
      for(let i =0 ; i<CurrentCart.ProductCartDetail.length; i++){
      // console.log("counter",CurrentCart.ProductCartDetail[i])
      if(CurrentCart.ProductCartDetail[i].ProductId!==productCart.ProductId){
        oldCartDetail.push(CurrentCart.ProductCartDetail[i]);
     }
     }

    //  console.log("filtered list",oldCartDetail)

    oldCart.ProductCartDetail = cloneDeep(oldCartDetail) ;
    
    SetCurrentCart(oldCart);
    //CurrentCart = oldCart;
    setCurrentCart(oldCart);
    sessionStorage.setItem("keyName", JSON.stringify(oldCart));
    DrawCart= cloneDeep(oldCart);
     handleClick();
  }      
  


  return (
    <div>
    {
      currentCart.ProductCartDetail.length > 0 ?
      currentCart.ProductCartDetail.map((item, index) => {
          return <div key={index} className="row  cart-detail">
            <div className="col-lg-4 col-sm-4 col-4 cart-detail-img">
              <img src={GetCurrentImage(item)} alt="" />
            </div>
            <div className="col-lg-8 col-sm-8 col-8 cart-detail-product">
              <p>{item.ProductName}</p>
              <span className="price " style={{fontSize:"16px"}} >Bs. {item.ProductSalePrice} P/U</span> <span className="count" style={{fontSize:"16px"}}> Cantidad:{item.ProductQuantity}</span>

              {/* <i className="icon-trash"  style={{"float":"right" }}  onClick={() => { DropCartItem(item) }}></i> icon-trash
             <i  className="fa fa-trash cart-icon2" aria-hidden="true"></i> */}

             <i onClick={() => { DropCartItem(item) }} className="fa fa-trash cart-icon2 icon-text"  style={{"float":"right" }} aria-hidden="true"></i>
         

              {/* <i onClick={() => { DropCartItem(item) }} className="fa fa-trash cart-icon2" aria-hidden="true"></i> */}
              {/* <button style={{"float":"right" }} onClick={() => { DropCartItem(item) }} >x</button> */}
            </div>
          </div>
        }) : <></>
    }

        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning">
          Objeto quitado del carrito
        </Alert>
      </Snackbar>
</div>
  )


}

export default AppNavBarCart;

