import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { ProductDetail, Cart, OrderDetailItem } from '../Models/Models';
import TouchRipple from '@material-ui/core/ButtonBase/TouchRipple';
import { Tooltip, IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    minHeight:380,
     borderWidth:"14px",    
   
  },
  media: {
    height: 200,
    paddingTop: '90%', // 16:9

  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: "#14A69B",
  },
  avatar2: {borderWidth:"14px",
    backgroundColor: "#14A69B",
    
  },
  avatar3: {
    backgroundColor: "white", 
    "&:hover": {
      backgroundColor: "transparent"
    }   
  },
  avatar4: {borderWidth:"14px",
    backgroundColor: "Orange",        
  },
  title:
  {
    fontSize:"1.5rem",minHeight:"100px",maxHeight:"100px",overflow:"hidden",overflowY:"auto"
  }
}));

interface CardItemProps{

  ProductDetailItem : ProductDetail;
  SetCurrentCart: Function;
  CurrentCart:Cart;
  HandleClick : Function;
} 


const CardItem: React.FC<CardItemProps> = ({ProductDetailItem,SetCurrentCart,CurrentCart,HandleClick}) => {
const classes = useStyles();


function AddToCart(){

let cart : Cart ={UserName:"New",UserNit:"",ProductCartDetail:[]}

var guardado = sessionStorage.getItem('keyName');

    if(guardado!=null){
      cart = JSON.parse(guardado);
  
    }else{
      
    }
let CartDetail : OrderDetailItem[] = cart.ProductCartDetail;

let NewCartDetail : OrderDetailItem[] = FindInDetail(CartDetail);

cart.ProductCartDetail=NewCartDetail;

SetCurrentCart(cart);
sessionStorage.setItem("keyName", JSON.stringify(cart));

HandleClick();


}

function DropToCart(){

  let cart : Cart ={UserName:"New",UserNit:"",ProductCartDetail:[]}
  
  var guardado = sessionStorage.getItem('keyName');
  
      if(guardado!=null){
        cart = JSON.parse(guardado);
    
      }else{
        
      }
  let CartDetail : OrderDetailItem[] = cart.ProductCartDetail;
  
  // let NewCartDetail : OrderDetailItem[] = FindInDetailtoDrop(CartDetail);
  
  // cart.ProductCartDetail=NewCartDetail;
  
  SetCurrentCart(cart);
  sessionStorage.setItem("keyName", JSON.stringify(cart));
  
  HandleClick();
  
  
  }

function GetCurrentImage(){

  

  return ProductDetailItem.ProductImageURL.toString();

 
}
function isOnCart(){

  let cart : Cart ={UserName:"New",UserNit:"",ProductCartDetail:[]}
  
  var guardado = sessionStorage.getItem('keyName');
  
      if(guardado!=null){
        cart = JSON.parse(guardado);
    
      }
  let CartDetail : OrderDetailItem[] = cart.ProductCartDetail;
  
  return FindInDetail2(CartDetail);
  
  }


function FindInDetail( ProductCartDetail:OrderDetailItem[]){
  let status: boolean = false;
  for (let counter = 0; counter < ProductCartDetail.length; counter++) {

    if (ProductCartDetail[counter].ProductId === ProductDetailItem.ProductId) {

      let quantity: number = +ProductCartDetail[counter].ProductQuantity;
      ProductCartDetail[counter].ProductQuantity = quantity + 1;
      // ProductCartDetail[counter].ProductTotal = ProductCartDetail[counter].ProductQuantity * ProductCartDetail[counter].ProductSalePrice;
      status = true;
    }
  }
  if (!status) {
    // let newItem: OrderDetailItem = { ProductId: ProductDetailItem.ProductId, ProductName: ProductDetailItem.ProductName, ProductSalePrice: ProductDetailItem.ProductSalePrice, ProductQuantity: 1, ProductTotal: ProductDetailItem.ProductSalePrice , ProductImage:ProductDetailItem.ProductImageURL }

    let newItem: OrderDetailItem = { ProductId: ProductDetailItem.ProductId, ProductName: ProductDetailItem.ProductName, ProductSalePrice: ProductDetailItem.ProductSalePrice, ProductQuantity: 1,  ProductImage:ProductDetailItem.ProductImageURL,description:"" }
    ProductCartDetail.push(newItem);
  }
  return ProductCartDetail;

}
function FindInDetailtoDrop( ProductCartDetail:OrderDetailItem[]){
  let status: boolean = false;
  for (let counter = 0; counter < ProductCartDetail.length; counter++) {

    if (ProductCartDetail[counter].ProductId === ProductDetailItem.ProductId) {

      let quantity: number = +ProductCartDetail[counter].ProductQuantity;
      ProductCartDetail[counter].ProductQuantity = quantity - 1;
      // ProductCartDetail[counter].ProductTotal = ProductCartDetail[counter].ProductQuantity * ProductCartDetail[counter].ProductSalePrice;
      status = true;
    }
  }
  if (!status) {
    // let newItem: OrderDetailItem = { ProductId: ProductDetailItem.ProductId, ProductName: ProductDetailItem.ProductName, ProductSalePrice: ProductDetailItem.ProductSalePrice, ProductQuantity: 1, ProductTotal: ProductDetailItem.ProductSalePrice , ProductImage:ProductDetailItem.ProductImageURL }

    let newItem: OrderDetailItem = { ProductId: ProductDetailItem.ProductId, ProductName: ProductDetailItem.ProductName, ProductSalePrice: ProductDetailItem.ProductSalePrice, ProductQuantity: 1,  ProductImage:ProductDetailItem.ProductImageURL,description:"" }
    ProductCartDetail.push(newItem);
  }
  return ProductCartDetail;

}

function FindInDetail2( ProductCartDetail:OrderDetailItem[]){
  let status: boolean = false;
  for (let counter = 0; counter < ProductCartDetail.length; counter++) {

    if (ProductCartDetail[counter].ProductId === ProductDetailItem.ProductId) {

      let quantity: number = +ProductCartDetail[counter].ProductQuantity;
      return quantity;
    }
  }
  
  return 0;
}
function getCurrentQuantity(ProductDetailItem : ProductDetail){



}
  
  return (
    <Card className={classes.root}   >
      <CardHeader
      classes={{
        title: classes.title,
      }}
        avatar={
<Tooltip title="Agregar">
  <IconButton aria-label="Agregar">
   <Avatar aria-label="recipe" className={classes.avatar} onClick={AddToCart}>
            +
          </Avatar>
  </IconButton>
</Tooltip>

          
        }
        
        title={ProductDetailItem.ProductName}
        
      />
      <CardMedia
        className={classes.media}
        image={GetCurrentImage()}
      />
      <CardContent>
 
        <Typography variant="body2" color="textSecondary" component="p" style={{height:"100px", overflow:"hidden",overflowY:"auto",fontSize:"1.5rem"}}>
          {ProductDetailItem.ProductDescription}
        </Typography>

        {/* <div style={{ minHeight:"40px"}}>{isOnCart()>0? <div  >  
        <Avatar aria-label="recipe" className={classes.avatar2}  onClick={DropToCart} style={{float: "left",display:"inline-block"}}
        >
          <div style={{marginTop:"15px"}}>-</div>
          </Avatar> 
          <Typography variant="body2" color="textSecondary" component="p" style={{fontSize:"18px","display": "inline-block"  }}>
          Cantidad: {isOnCart()}
        </Typography>
       
        <Avatar aria-label="recipe" className={classes.avatar2} onClick={AddToCart} style={{float: "right",display:"inline-block"}} >
        <div style={{marginTop:"15px"}}>+</div>
          </Avatar></div>:<></>}
          </div> */}

          <br/>
          <div style={{height:'60px'}}>          
          <div style={{display:'inline-block'}}>
          { isOnCart()>0?
         <Tooltip title="Cantidad actual">
          <IconButton aria-label="Agregar">
           <Avatar aria-label="recipe" className={isOnCart()>0?classes.avatar4:classes.avatar3} >
                    {isOnCart()}
                  </Avatar>
          </IconButton>
        </Tooltip>:<IconButton className={classes.avatar3}>
           <Avatar aria-label="recipe" className={isOnCart()>0? classes.avatar4:classes.avatar3} >
                    {isOnCart()}
                  </Avatar>
          </IconButton>
        }          
          </div>
          <div  style={{display:'inline-block'}}>
          <Typography variant="body2" color="textSecondary" component="p" style={{fontSize:"22px"}}>
          Bs.{ProductDetailItem.ProductSalePrice}
        </Typography>
        
          </div>
          </div>
       
      </CardContent>
    </Card>
  );
}

export default CardItem;
