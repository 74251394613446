import Modal from '@material-ui/core/Modal';
import React from 'react';
import { ClientModel } from '../Models/Models';
import { fetchViewList8 } from '../Services/Services';
import '../Styles/LoginFormScss.css'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 300,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);
function rand() {
  return 0;
    // return Math.round(Math.random() * 20) - 10;
  }
  
  function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  

const LoginForm: React.FC = () => {

    const [modalStyle] = React.useState(getModalStyle);
    const [hidden, setHidden] = React.useState(false);
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const classes = useStyles();
    setInterval(function () {
        var modalValue = sessionStorage.getItem('cart');
        var modalValue2 = sessionStorage.getItem('cart2');
    
        if (modalValue) {
          // use the value
          // remove the value when you are done so that this code doesn't run every time
          if (modalValue === "hover"  ) {
            //console.log("11")
            
            setHidden(true) } else if ( modalValue2!=undefined && modalValue2 =="hover") {
               // console.log("22")
            
                setHidden(true) } else { setHidden(false) }

         
    
          // sessionStorage.setItem( 'key', modalValue );
        }
      }, 100);
 
      async function Login(){
        if(username!=="" && password!==""){
            let userResult : any = await fetchViewList8(username,password)
        if(userResult!==null && userResult!= undefined){
          console.log("userResult",userResult)
           let user :ClientModel = userResult.user;
           // DrawCart.UserName=user.email;
            
           // sessionStorage.setItem("keyName", JSON.stringify(DrawCart));
            sessionStorage.setItem("user", JSON.stringify(user));  
            window.location.reload();
            setUsername("")
            setPassword("") 
            handleClose(); 
            
        }else{
            console.log("user invalid")
        }
    }     
          //  let aux = sessionStorage.getItem('fromCheckout');
           // if(aux!== null && aux !==undefined){
             // history.push("/checkout");
            //  window.location.reload();
          //    sessionStorage.removeItem('fromCheckout')
          //    
         //   }else{
         //            history.push("/");
         //   window.location.reload();
         //   }     
     //   }else{

     //     handleClick1();
      //  }
      //  }else{
        //  handleClick1();
      //
    }
    const handleClose = () => {
        setHidden(false);
        sessionStorage.setItem('cart','unhover')
        sessionStorage.setItem('cart2','unhover')
      };
    //   style={{zIndex:"initial"}}
    return (

        <Modal
        open={hidden}
        onClose={handleClose}
  
      >   
      
      <div style={modalStyle} className={classes.paper} >
      <section id="contact-form">
            <div >
                <div className="row">
                    <div >
                        <div className="block">
                            <div> 
                                
                                    <h2 style={{textAlign:"center", marginTop:"-30px",marginBottom:"20px"}} >Iniciar Sesión</h2>
                                    <br></br>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Account"   value={username} onInput={e => setUsername((e.target as HTMLInputElement).value)} />
                                </div>
                                <div >
                                    <input type="password" className="form-control" placeholder="Password"  value={password} onInput={e => setPassword((e.target as HTMLInputElement).value)} />
                                </div>
                                <br></br>
                                
                                <div className="form-group">
                                <button className="btn btn-view-works"  style ={{marginBottom:"-30px"}}onClick={()=>{Login()}}>Iniciar Sesión</button>
                                    {/* <input type="text" className="form-control" placeholder="Email Address" /> */}
                                </div>
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
     
        </div>
       
      </Modal>

     
    );
}
export default LoginForm;